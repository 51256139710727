import React, { useEffect, useState, useRef, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import CartModal from "../Modals/cart_modal";
import DataContext from '../Elements/eventContext';
import sessionCartData from "./cart_session_data";
function MobileHeader({ PageName, Route, cartCount = 0 }) {
    const didMountRef = useRef(true);

    const [showCart, setShowCart] = useState(false);
    const handleShowCart = () => setShowCart(true);
    const handleChildCartData = (status) => {
        setShowCart(status)
    };
    const handleClose = () => {
        setShowCart(false);
    };
    const contextValues = useContext(DataContext);
    const cartModal = () => {
        contextValues.setToggleCartModal(!contextValues.toggleCartModal)
    }
    const [showHeaderStatus, SetShowHeaderStatus] = useState(false)
    const location = useLocation();
    const { slug } = useParams();
    const dataArray = sessionCartData();
    useEffect(() => {
        if (didMountRef.current) {
            const currentPath = location.pathname;
            const isCategoryPage = currentPath.includes("/category");
            const isProductDetailsPage = currentPath.includes("/product/" + slug);
            const isTagPage = currentPath.includes("/collection/tag/" + slug);
            if (isCategoryPage || isProductDetailsPage || isTagPage) {
                SetShowHeaderStatus(true)
            } else {
                SetShowHeaderStatus(false)
            }
        }
        didMountRef.current = false;
        contextValues.setAddressSession(dataArray[0]) 
        contextValues.setCartSessionData(dataArray[1]) 
        contextValues.setCouponSession(dataArray[2]) 
        contextValues.setCartSummary(dataArray[3])
        contextValues.setCartCount(dataArray[1].length)
        contextValues.setUserToken(dataArray[5])
        const storedLocation = JSON.parse(localStorage.getItem("DELIVERY_LOCATION"));
        contextValues.setDeliveryLocationData(storedLocation)
    }, []);
    return (
        <>
            <header className="mobile-header">
                <div className="mobile-header-left">
                    <a href={"/" + Route} className="mobile-header-title"><i className="ri-arrow-left-line ri-xl mr-10"></i> {PageName}</a>
                </div>
                <div className="mobile-header-right">
                    <a className="search-link mr-15" href="/search" title="search">
                        <i className="ri-search-line ri-xl"></i>
                    </a>
                    <a className='mobile-cart-toggle' title="cart" href="javascript:;" onClick={(e) => cartModal()}>
                        <i className="ri-shopping-bag-3-line ri-xl"></i>
                        <span className="cart-count">{contextValues.cartCount}</span>
                    </a>
                </div>
            </header>

            {contextValues.toggleCartModal ? <CartModal /> : null}
        </>
    );
}
export default MobileHeader;
