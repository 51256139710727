import React, { useEffect, useState, useRef, useContext } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { ApiService } from "../../Components/Services/apiservices";
import { BrowserView, MobileView } from "react-device-detect";
import AccountSidebar from "./account_sidebar";
import { useNavigate } from "react-router-dom";
import MobHeader from "../../Components/Header/mobheader";
import moment from "moment";
import DataContext from "../../Components/Elements/eventContext";
import Loader from "react-js-loader";
import orderStatusComponent from "../../Components/Elements/order_status";
function Orders() {
  const didMountRef = useRef(true);
  const contextValues = useContext(DataContext);
  const [orderData, setOrderData] = useState([]);
  const [itemsData, setItemsData] = useState([]);
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (didMountRef.current) {
      getorderData()
    }
    didMountRef.current = false;
  }, []);
  const getorderData = () => {
    ApiService.fetchData("get-order-data").then((res) => {
      if (res.status == "success") {
        setOrderData(res.orderList);
        setItemsData(res.orderList.items);
        setTimeout(() => {
          setSpinnerLoading(false);
        }, 500);
      } else {
        setTimeout(() => {
          setSpinnerLoading(false);
        }, 500);
      }
    });
  };
  const handleShopNow = () => {
    navigate("/");
  };

  return (
    <>
      <BrowserView>
        <Header state="inner-header" />
        <div className="subheader">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">My Orders</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <section className="section-gap-medium">
          <div className="container">
            <div className="row">
              <AccountSidebar />
              <div className="col-lg-9">
                <div className="acpanel">
                  <div className="acpanel-header">
                    <h4>My Orders</h4>
                  </div>
                  <div className="acpanel-body" >
                    {spinnerLoading === false ?
                      orderData.length > 0 ? (
                        orderData.map((value, index) => (
                          <div className="order-box" key={index}>
                            <div className="info">
                              <div className="info-delivery">
                                <h5 className="mb-10">Delivery Address</h5>
                                <p className="mb-0 tx-16">{value.trans_user_name}</p>
                                <p className="mb-2">
                                  {value.trans_delivery_address}
                                </p>
                                <p className="mb-0">
                                  <strong>Email Id :</strong> {value.trans_user_email}
                                </p>
                                <p className="mb-0">
                                  <strong>Phone number :</strong> {value.trans_user_mobile}
                                </p>
                                <div className="orderitems">
                                  <ul>
                                    {value.items.map((itemsValue, itemsIndex) => {
                                      return (
                                        <li key={itemsIndex}><img src={itemsValue.td_item_image}></img></li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </div>
                              {/* {itemsData.map((value, index) => {
                          return (
                        <div className="order-box-items-content">
                                {(() => {
                                  return orderStatusComponent(value)
                                })()}
                              </div>
                                 );
                                })} */}
                            </div>
                            <div className="bcode">
                              <div className="orderid-box mb-2">
                                <h6 className="mb-0">ORDER ID</h6>
                                <p className="mb-0 tx-13">{value.trans_order_number}</p>
                              </div>
                              <p className="tx-color-03 mb-0 tx-13">ORDER ON</p>
                              <p className="tx-12">
                                {moment(value.trans_datetime).format("ddd, DD MMM YYYY")}
                                <br />
                                {moment(value.trans_datetime, "HH:mm").format("hh:mm A")}
                              </p>
                              <a
                                href={`/account/order-details/${value.trans_order_number}`}
                                className="btn btn-outline-primary btn-sm btn-full"
                              >
                                View Details
                              </a>
                            </div>
                          </div>
                        ))
                      ) :
                        <div className="noimg text-center">
                          <img
                            src="/images/no-orders.webp"
                            alt="No Orders"
                            style={{ width: '250px' }}
                            className="mb-3"
                          />
                          <h6>No orders found!</h6>
                          <p>Look like you haven't made your order yet</p>
                          <div className="btn btn-outline-primary btn-md" onClick={handleShopNow}>
                            Shop Now
                          </div>
                        </div>
                      : <Loader type="spinner-cub" bgColor={'#1b8057'} color={'#1b8057'} size={50} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </BrowserView>
      <MobileView>
        <MobHeader PageName="Your Orders" Route="account/account-overview" />
        <div id="appCapsule">
          {spinnerLoading === false ?
            orderData.length > 0 ? (
              orderData.map((value, index) => (
                <div className="morderbox" key={index}>
                  <div className="morderbox-body">
                    <div className="row">
                      <div className="col-7">
                        <h6 className="mb-1">Order Id: {value.trans_order_number}</h6>
                        <p className="mb-1 tx-14">Total Amount: ₹{value.trans_amt}</p>
                        <p className="mb-0 tx-14 tx-gray">Total Items: {value.itemscount}</p>
                      </div>
                      <div className="col-5 tx-right">
                        <p className="mb-1  tx-gray">Placed On</p>
                        <p className="mb-0 tx-12 tx-gray">{moment(value.trans_datetime).format("ddd, DD MMM YYYY")} @  {moment(value.trans_datetime, "HH:mm").format("hh:mm A")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="morderbox-footer">
                    <span className="badge bg-warning">Processing</span>
                    <a href={`/account/order-details/${value.trans_order_number}`}>View Details<i className="ri-arrow-right-line ml-10"></i></a>
                  </div>
                </div>
              ))
            ) :
              <div className="noimg text-center">
                <img
                  src="/images/no-orders.webp"
                  alt="No Orders"
                  style={{ width: '250px' }}
                  className="mb-3"
                />
                <h6>No orders found!</h6>
                <p>Look like you haven't made your order yet</p>
                <div className="btn btn-primary-outline btn-medium btn01" onClick={handleShopNow}>
                  Shop Now
                </div>
              </div>
            : <Loader type="spinner-cub" bgColor={'#1b8057'} color={'#1b8057'} size={50} />}
        </div>

      </MobileView>



    </>
  );
}
export default Orders;
