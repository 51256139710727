import React, { useEffect, useState, useRef, useCallback, useContext } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { ApiService } from "../../Components/Services/apiservices";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import constant from "../../Components/Services/constant";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";
import "swiper/css/free-mode";
import 'swiper/css/scrollbar';
import { FreeMode, Navigation, Thumbs, Autoplay, Pagination } from "swiper/modules";
import sessionCartData from "../../Components/Elements/cart_session_data";
import StarRating from "../../Components/Elements/starrating";
import multiCurrency from "../../Components/Elements/multi_currrency";
import ProductBox from "../../Components/Elements/product_box";
import MobileHeader from "../../Components/Elements/mobile_header";
import Accordion from "react-bootstrap/Accordion";
import { addToCartDetailPage } from "../../Components/Elements/add_to_cart";
import DataContext from "../../Components/Elements/eventContext";
import Loader from "react-js-loader";
import moment from "moment/moment";
import ReviewModal from "../../Components/Modals/review_modal";
import ReviewListModal from "../../Components/Modals/review_list_modal";
function Productdetails() {
  const didMountRef = useRef(true);
  const contextValues = useContext(DataContext);
  const [rowProductsData, setProductsData] = useState({});
  const [relatedProducts, setRelatedProducts] = useState([]);
  const dataArray = sessionCartData();
  const parsedRecentlyProductsSession = dataArray[4];
  const [quantity, setQuantity] = useState(1);
  const [adminData, setAdminData] = useState({});
  const [productsTab, setProductsTab] = useState({});
  const [variationData, setVariationData] = useState([]);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [arySelectedData, setArySelectedData] = useState([]);
  const [selvararray, setSelvararray] = useState([]);
  const [galleryItems, setGalleryItems] = useState([]);
  const [productDataGallery, setProductDataGallery] = useState([])
  const [ReviewData, setReviewData] = useState([]);
  const [PercentageData, setPercentageData] = useState([]);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [SubmitReview, setSubmitReview] = useState(false);
  const [showViewAllReview, setShowViewAllReview] = useState(false);
  const [setSession, SetSession] = useState("");
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [recentlyViewedData, setRecentlyViewedData] = useState(
    parsedRecentlyProductsSession
  );
  const sliderRef = useRef(null);
  const tagsRef = useRef(null);
  const { slug } = useParams();
  const taghandlePrev = useCallback(() => {
    if (!tagsRef.current) return;
    tagsRef.current.swiper.slidePrev();
  }, []);

  const taghandleNext = useCallback(() => {
    if (!tagsRef.current) return;
    tagsRef.current.swiper.slideNext();
  }, [])

  const navRef = useRef(null);
  const navhandlePrev = useCallback(() => {
    if (!navRef.current) return;
    navRef.current.swiper.slidePrev();
  }, []);

  const navhandleNext = useCallback(() => {
    if (!navRef.current) return;
    navRef.current.swiper.slideNext();
  }, [])



  let mrpValue = 0;
  let sellingPriceValue = 0;
  let discount = 0;
  useEffect(() => {
    if (didMountRef.current) {
      SetSession(localStorage.getItem("USER_TOKEN"));
      getProductDetails();
    }
    didMountRef.current = false;
  }, []);

  const loginModal = () => {
    contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
  }
  const recentlyProducts = (value) => {
    //localStorage.removeItem("RECENTLY_VIEWED");
    let recentlyProductsSession = localStorage.getItem("RECENTLY_VIEWED");
    recentlyProductsSession = recentlyProductsSession
      ? JSON.parse(recentlyProductsSession)
      : [];

    if (!Array.isArray(recentlyProductsSession)) {
      recentlyProductsSession = [];
    }

    const existingProductIndex = recentlyProductsSession.findIndex((item) => {
      return item.product_id === value.product_id;
    });

    if (existingProductIndex !== -1) {
      recentlyProductsSession[existingProductIndex] = value;
    } else {
      if (recentlyProductsSession.length >= 10) {
        recentlyProductsSession.shift();
      }
      recentlyProductsSession.push(value);
      localStorage.setItem(
        "RECENTLY_VIEWED",
        JSON.stringify(recentlyProductsSession)
      );
    }
  };

  const productCarouselOptions = {
    loop: true,
    spaceBetween: 0,
    breakpoints: {
      0: {
        slidesPerView: 2.3,
      },
      600: {
        slidesPerView: 2,
      },
      1000: {
        slidesPerView: 6,
      },
    },
  };
  const productCarouselOptionss = {
    loop: true,
    spaceBetween: 15,
    breakpoints: {
      0: {
        slidesPerView: 2.3,
      },
      600: {
        slidesPerView: 2,
      },
      1000: {
        slidesPerView: 6,
      },
    },
  };
  const getProductDetails = () => {
    const dataString = {
      slug: slug,
    };
    setSpinnerLoading(true)
    ApiService.postData("product-details", dataString).then((res) => {
      if (res.status === "success") {
        recentlyProducts(res.rowProductData);
        setSelvararray(res.selvararray);
        setProductsData(res.rowProductData);
        setRelatedProducts(res.relatedProducts);
        setAdminData(res.admin_data);
        setVariationData(res.variationData);
        setReviewData(res.review_data);
        setPercentageData(res.percentageData);
        setProductsTab(res.rowProductData.product_tabs);
        setProductDataGallery(res.rowProductData.product_image_gallery)
        mrpValue = parseFloat(res.rowProductData.product_price);
        sellingPriceValue = parseFloat(
          res.rowProductData.product_selling_price
        );
        if (!isNaN(mrpValue) && !isNaN(sellingPriceValue)) {
          discount = ((mrpValue - sellingPriceValue) / mrpValue) * 100;
          setDiscountPercentage(discount.toFixed(2));
        }
        const galleryItems = [
          {
            original:
              res.rowProductData.product_image != null
                ? res.rowProductData.product_image
                : constant.DEFAULT_IMAGE
          },
        ];

        res.rowProductData.product_image_gallery?.map((value) => {
          galleryItems.push({
            original:
              value.gallery_image != null
                ? value.gallery_image
                : constant.DEFAULT_IMAGE
          });
        });
        setGalleryItems(galleryItems);
        let parentcounter = 0;
        let childcounter = 0;
        res.variationData.map((parent) => {
          if (parent.attributes && parent.attributes.attribute_type === 3) {
            parent.attr_terms.map((child) => {
              parentcounter++;
              if (parentcounter === 1) {
                arySelectedData.push(child.terms_name);
              }
            });
            parentcounter = 0;
          } else if (
            parent.attributes &&
            parent.attributes.attribute_type === 2
          ) {
            parent.attr_terms.map((child) => {
              childcounter++;
              if (childcounter === 1) {
                arySelectedData.push(child.terms_name);
              }
            });
            childcounter = 0;
          } else if (
            parent.attributes &&
            parent.attributes.attribute_type === 1
          ) {
            parent.attr_terms.map((child) => {
              childcounter++;
              if (childcounter === 1) {
                arySelectedData.push(child.terms_name);
              }
            });
            childcounter = 0;
          }
        });
        setSpinnerLoading(false)
      } else {
        setSpinnerLoading(false)
      }
    });
  };

  const variationSelect = (item, index) => {
    const updatedSelectedData = [...arySelectedData];
    updatedSelectedData[index] = item.terms_name;
    const selvararrayselect = updatedSelectedData;
    const dataString = {
      variation: selvararrayselect,
      product_id: rowProductsData.product_id,
    };
    ApiService.postData("variation-wise-price", dataString).then((res) => {
      setArySelectedData(selvararrayselect)
      setSelvararray(selvararrayselect);
      rowProductsData.product_sku = res.data.pv_sku;
      rowProductsData.product_selling_price = res.data.pv_sellingprice;
      rowProductsData.product_price = res.data.pv_price;
      rowProductsData.product_stock = res.data.pv_quantity;
      rowProductsData.product_moq = res.data.pv_moq;
      rowProductsData.product_discount = res.data.pv_discount;
      if (item.variation_images) {
        rowProductsData.product_image =
          item.variation_images.pti_image != null
            ? item.variation_images.pti_image
            : constant.DEFAULT_IMAGE;
      } else {
        rowProductsData.product_image =
          rowProductsData.product_image != null
            ? rowProductsData.product_image
            : constant.DEFAULT_IMAGE;
      }
      setProductsData(rowProductsData);
      mrpValue = parseFloat(res.data.pv_price);
      sellingPriceValue = parseFloat(res.data.pv_sellingprice);
      if (!isNaN(mrpValue) && !isNaN(sellingPriceValue)) {
        discount = ((mrpValue - sellingPriceValue) / mrpValue) * 100;
      }
      setDiscountPercentage(discount.toFixed(2));
      setQuantity(1);
    });
  };

  const handleAddToCart = async () => {
    contextValues.setSpinnerCubLoader(rowProductsData.product_id)
    const productData = {
      product_id: Number(rowProductsData.product_id),
      product_name: rowProductsData.product_name,
      product_slug: rowProductsData.product_slug,
      product_image: rowProductsData.product_image
        ? rowProductsData.product_image
        : constant.DEFAULT_IMAGE,
      product_type: Number(rowProductsData.product_type),
      product_price: Number(rowProductsData.product_price),
      product_selling_price: Number(rowProductsData.product_selling_price),
      product_discount: rowProductsData.product_discount,
      product_variation: arySelectedData,
      product_category_id: rowProductsData.product_category_id,
    };
    const updateStatus = await addToCartDetailPage(productData, quantity, contextValues);
    if (updateStatus) {
      contextValues.setSpinnerCubLoader(0)
    } else {
      contextValues.setSpinnerCubLoader(0)
    }
  };


  const handleIncrease = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const RatingSection = () => {
    const ratings = [5, 4, 3, 2, 1];
    return (
      <div>
        {ratings.map((stars, index) => (
          <div key={index} className="ratings-item">
            <div className="ratings-container mb-0">
              <StarRating numberOfStars={stars} />
            </div>
            <div className="rating-percent">
              <span style={{ width: Math.round(PercentageData[stars]) + "%" }}></span>
            </div>
            <div className="rating-value">{Math.round(PercentageData[stars])}%</div>
          </div>
        ))}
      </div>
    );
  };

  const reviewModal = () => {
    contextValues.setToggleReviewModal(!contextValues.toggleReviewModal)
  }

  const reviewListModal = () => {
    contextValues.setProductsData(rowProductsData)
    contextValues.setToggleReviewListModal(!contextValues.toggleReviewListModal)
  }
  return (
    <>
      <BrowserView>
        <Header />
        <section className="section-gap-small product-single">
          <div className="container">
            <div className="row">
              {spinnerLoading ?
                <>
                  <div className="col-lg-6">
                   <div className="pss-slider">
                      <div
                        className="mySwiper2 gallery-top">
                        <div>
                          <div className="gallery-page__single">
                            <div className="gallery-page__video">
                              <Skeleton
                                variant="text"
                                width="100%"
                                height={600}
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="gallery-page__single">
                            <div className="gallery-page__video">
                              <Skeleton
                                variant="text"
                                height={142.5}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mySwiperv gallery-thumbs">
                        <div><Skeleton variant="text" width={100} height={142.5} /></div>
                        <div><Skeleton variant="text" width={100} height={142.5} /></div>
                        <div><Skeleton variant="text" width={100} height={142.5} /></div>
                        <div><Skeleton variant="text" width={100} height={142.5} /></div>
                      </div>
                    </div> 
                  </div>
                  <div className="col-lg-6">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Skeleton variant="text" height={15} width={50} /></li>
                        <li className="breadcrumb-item active" aria-current="page"><Skeleton variant="text" height={15} width={100} /></li>
                      </ol>
                    </nav>
                    <div className="product-details">
                      <h1 className="product-name"><Skeleton variant="text" height={25} width={350} /></h1>
                      <h1 className="product-name"><Skeleton variant="text" height={15} width={200} /></h1>
                      <div className="price mt-3">
                        <ins className="new-price"><Skeleton variant="text" height={15} width={50} /></ins>
                        <del className="old-price"><Skeleton variant="text" height={15} width={50} /></del>
                        <span className="off"><Skeleton variant="text" height={15} width={50} /></span>
                      </div>
                      <h1 className="product-name mt-3"><Skeleton variant="text" height={50} width={500} /></h1>
                      <h1 className="product-name mt-3"><Skeleton variant="text" height={50} width={500} /></h1>
                    </div>
                  </div>
                </>
                :
                <>
                  <div className="col-lg-6">
                    <div className="pss-slider">
                      <Swiper
                        loop={false}
                        ref={sliderRef}
                        spaceBetween={0}
                        thumbs={{
                          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
                        }}
                        modules={[Autoplay, FreeMode, Thumbs]}
                        className="mySwiper2 gallery-top">
                        {galleryItems.map((value, index) => (
                          <SwiperSlide key={index}>
                            <div className="gallery-page__single">
                              <div className="gallery-page__video">
                                <img className="w-100" src={value.original} alt={rowProductsData.product_name} />
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                      <Swiper
                        onSwiper={setThumbsSwiper}
                        direction="vertical"
                        loop={false}
                        spaceBetween={10}
                        slidesPerView={4}
                        freeMode={false}
                        modules={[FreeMode, Thumbs]}
                        className="mySwiperv gallery-thumbs">
                        {galleryItems.map((value, index) => (
                          <SwiperSlide key={index}>
                            <img src={value.original} alt={`Thumbnail ${index}`} />
                          </SwiperSlide>
                        ))}
                      </Swiper>

                    </div>
                  </div>
                  <div className="col-lg-6">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page">
                          {rowProductsData.product_name}
                        </li>
                      </ol>
                    </nav>
                    <div className="product-details">
                      <h1 className="product-name">{rowProductsData.product_name}</h1>
                      <div className="product-meta">
                        {rowProductsData.product_brand_name ? (
                          <>
                            Brand:
                            <span className="product-sku">
                              {rowProductsData.product_brand_name}
                            </span>
                          </>
                        ) : null}
                      </div>
                      {rowProductsData.has_many_reviews && rowProductsData.has_many_reviews.length > 0 && rowProductsData.product_rating &&
                        rowProductsData.product_rating > 0 ? (
                        <div className="cratings-container"><StarRating numberOfStars={rowProductsData.product_rating} reviewCount={rowProductsData.has_many_reviews.length} /></div>
                      ) : (
                        ""
                      )}
                      <div className="price mt-3">
                        <ins className="new-price">
                          {multiCurrency(rowProductsData.product_selling_price)}
                        </ins>

                        {discountPercentage > 0 ? (
                          <>
                            <del className="old-price">
                              {multiCurrency(rowProductsData.product_price)}
                            </del>
                            <span className="off">{Math.round(discountPercentage)}% Off</span>
                          </>
                        ) : null}
                      </div>
                      {/* Variation */}
                      <div className="product-details-size mt-4">
                        {variationData.map((valueVariation, indexVariation) => {
                          if (
                            valueVariation.attributes &&
                            valueVariation.attributes.attribute_type === 1
                          ) {
                            // COLOR
                            return (
                              <div className="mt-20" key={indexVariation}>
                                <h6>
                                  {valueVariation.attributes.attribute_name}:
                                </h6>
                                <ul>
                                  {valueVariation.attr_terms.map(
                                    (
                                      valueVariationAttr,
                                      indexvalueVariationAttr
                                    ) => {
                                      const stringIncluded = selvararray.includes(
                                        valueVariationAttr.terms_name
                                      );
                                      const className = stringIncluded
                                        ? "color active"
                                        : "color";
                                      return (
                                        <li
                                          onClick={() =>
                                            variationSelect(
                                              valueVariationAttr,
                                              indexVariation
                                            )
                                          }
                                          className={className}
                                          key={indexvalueVariationAttr}
                                          style={{
                                            backgroundColor:
                                              valueVariationAttr.terms_value,
                                            display: "block",
                                          }}
                                        ></li>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            );
                          } else if (
                            valueVariation.attributes &&
                            valueVariation.attributes.attribute_type === 2
                          ) {
                            // IMAGE
                            return (
                              <div className="mt-20" key={indexVariation}>
                                <h6>
                                  {valueVariation.attributes.attribute_name}:
                                </h6>
                                <ul>
                                  {valueVariation.attr_terms.map(
                                    (
                                      valueVariationAttr,
                                      indexvalueVariationAttr
                                    ) => {
                                      const stringIncluded = selvararray.includes(
                                        valueVariationAttr.terms_name
                                      );
                                      const className = stringIncluded
                                        ? "img active"
                                        : "img";
                                      return (
                                        <li
                                          onClick={() =>
                                            variationSelect(
                                              valueVariationAttr,
                                              indexVariation
                                            )
                                          }
                                          className={className}
                                          key={indexvalueVariationAttr}
                                        >
                                          <img
                                            src={
                                              valueVariationAttr.variation_images !=
                                                null
                                                ? valueVariationAttr
                                                  .variation_images.pti_image
                                                : constant.DEFAULT_IMAGE
                                            }
                                            alt={rowProductsData.product_name}
                                            width="100"
                                            height="100"
                                          />
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            );
                          } else if (
                            valueVariation.attributes &&
                            valueVariation.attributes.attribute_type === 3
                          ) {
                            // TEXT
                            return (
                              <div key={indexVariation}>
                                <h6>
                                  {valueVariation.attributes.attribute_name}:
                                </h6>

                                <ul>
                                  {valueVariation.attr_terms.map(
                                    (
                                      valueVariationAttr,
                                      indexvalueVariationAttr
                                    ) => {
                                      const stringIncluded = selvararray.includes(
                                        valueVariationAttr.terms_name
                                      );
                                      const className = stringIncluded
                                        ? "size active"
                                        : "size";
                                      return (
                                        <li
                                          onClick={() =>
                                            variationSelect(
                                              valueVariationAttr,
                                              indexVariation
                                            )
                                          }
                                          className={className}
                                          href="javascript:void(0)"
                                          key={indexvalueVariationAttr}
                                        >
                                          {valueVariationAttr.terms_name}
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                      <div className="stock-text mt-3">
                        Availability:
                        {Number(rowProductsData.product_type) === 0 ? (
                          Number(rowProductsData.product_inventory) === 1 ? (
                            (rowProductsData.product_stock === null || Number(rowProductsData.product_stock) <= 0) ? (
                              Number(rowProductsData.product_backorder) === 0 ||
                                Number(rowProductsData.product_backorder) === 1 ? (
                                <span className="outofdtock">Out of Stock</span>
                              ) : (
                                <span className="instock">In Stock</span>
                              )
                            ) : (
                              <span className="instock">In Stock</span>
                            )
                          ) : (
                            <span className="instock">In Stock</span>
                          )
                        ) : (rowProductsData.product_stock === null || Number(rowProductsData.product_stock) <= 0) ? (
                          <span className="outofdtock">Out of Stock</span>
                        ) : (
                          <span className="instock">In Stock</span>
                        )}
                      </div>
                      {rowProductsData.product_highlight ? (
                        <div className="product-highlight mt-4">
                          <ul>
                            {rowProductsData.product_highlight
                              .split("##")
                              .map((highlightvalue, indextag) => {
                                return <li key={indextag}>{highlightvalue}</li>;
                              })}
                          </ul>
                        </div>
                      ) : null}
                      <div className="product-details-footer">
                        <div className="dqtyadd">
                          <span onClick={(e) => handleDecrease()}><i className="ri-subtract-line"></i></span>
                          <span>{quantity}</span>
                          <span onClick={(e) => handleIncrease()}><i className="ri-add-line"></i></span>
                        </div>
                        <button type="button" className="addcartbutton" onClick={(e) => handleAddToCart()}>{contextValues.spinnerCubLoader == rowProductsData.product_id ? <Loader type="spinner-cub" bgColor={'#000000'} color={'#000000'} size={20} /> : 'Add to Cart'} </button>
                      </div>
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
        </section>
        <section className="section-gap-small">
          <div className="container">
            <div className="row">
              <div className="page-title mb-4 mt-4" >
                <h2 className="mb-0">Product Description</h2>
              </div>
              <div className="productDescription" dangerouslySetInnerHTML={{ __html: rowProductsData.product_description }}>
              </div>
            </div>
          </div>
        </section>
        {relatedProducts.length > 0 ? (<section className="section-gap-small">
          <div className="container">
            <div className="row">
              <div className="page-title mb-4 mt-4" >
                <h2 className="mb-0">Related Products</h2>
              </div>
              <div className="productSlider">
                <Swiper {...productCarouselOptionss}
                  ref={tagsRef}
                >
                  {relatedProducts.map((subvalue, indexProduct) => {
                    return (
                      <SwiperSlide key={indexProduct}>
                        <ProductBox productValue={subvalue} indexProduct={indexProduct} />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                <div className='swip-buttons'>
                  <div className="swip-button swip-button-prev" style={{ alignItems: 'center' }} onClick={taghandlePrev} >
                    <span>
                      <i className="ri-arrow-left-line"></i>
                    </span>

                  </div>
                  <div className="swiper-button swip-button-next" onClick={taghandleNext} style={{ alignItems: 'center' }}>
                    <span>
                      <i className="ri-arrow-right-line"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>) : ("")}
        <section className="section-gap-small">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-title mb-4">
                  <h2 className="mb-0">Rating & Reviews</h2>
                </div>
                <div eventKey="Reviews" title="Reviews">
                  {ReviewData && ReviewData.length > 0 ? (
                    <div className="reviewsec">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="avg-rating-container">
                            <mark>{rowProductsData.product_rating}</mark>
                            <div className="avg-rating">
                              <span className="avg-rating-title mb-1">
                                Average Rating
                              </span>
                              {ReviewData && ReviewData.length > 0 &&
                                rowProductsData.product_rating &&
                                rowProductsData.product_rating > 0 ? (
                                <div className="ratings-container mb-0">
                                  <StarRating
                                    numberOfStars={
                                      rowProductsData.product_rating
                                    }
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="ratings-list mb-4">
                            <RatingSection />
                          </div>
                          {setSession ? (
                            <button
                              className="btn btn-primary btn-md"
                              onClick={(e) => reviewModal()}
                            >
                              Submit Review
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary btn-md"
                              onClick={(e) => loginModal()}
                            >
                              Submit Review
                            </button>
                          )}
                        </div>
                        <div className="col-lg-8">
                          <div className="comentlist">
                            <ul>
                              {ReviewData.map((value, index) => (
                                <li key={index}>
                                  <div className="comment">
                                    <figure className="comment-media">
                                      <img
                                        src="/images/user.png"
                                        alt={rowProductsData.product_name}
                                      />
                                    </figure>
                                    <div className="comment-body">
                                      {value.pr_rating &&
                                        value.pr_rating > 0 ? (
                                        <div className="ratings-container mb-2">
                                          <StarRating
                                            numberOfStars={value.pr_rating}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="comment-rating"></div>
                                      <div className="comment-user">
                                        <span className="comment-meta">by <span className="comment-name">{value.pr_title}</span>on <span className="comment-date">{moment(value.pr_created).format("MMM D, YYYY")}</span></span>
                                      </div>
                                      <div className="comment-content">
                                        <p>{value.pr_review}</p>
                                      </div>
                                      {value.pr_image1 && value.pr_image1.split(',').length > 0 && (
                                        <div className="comment-images">
                                          {value.pr_image1.split(',').map((imageUrl, index) => (
                                            <img key={index} src={imageUrl} alt={`Image ${index + 1}`} width={50} />
                                          ))}
                                        </div>
                                      )}

                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                          {ReviewData.length > 0 ? (
                            <p
                              className="mb-0 tx-primary"
                              onClick={(e) => reviewListModal()}
                            >
                              View All Reviews
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ) : setSession ? (
                    <button
                      className="btn btn-primary btn-medium"
                      onClick={(e) => reviewModal()}
                    >
                      Submit Review
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary btn-medium"
                      onClick={(e) => loginModal()}
                    >
                      Submit Review
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        {recentlyViewedData.length > 0 ? (
          <section className="section-gap-small">
            <div className="container">
              <div className="row">
                <div className="page-title mb-4 mt-4" >
                  <h2 className="mb-0">Recently Viewed</h2>
                </div>
                <div className="productSlider">
                  <Swiper {...productCarouselOptionss}
                    ref={navRef}
                  >
                    {recentlyViewedData.map((subvalue, indexProduct) => {

                      return (
                        <SwiperSlide key={indexProduct}>
                          <ProductBox productValue={subvalue} indexProduct={indexProduct} />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>

                  <div className='swip-buttons'>
                    <div className="swip-button swip-button-prev" style={{ alignItems: 'center' }} onClick={navhandlePrev} >
                      <span>
                        <i className="ri-arrow-left-line"></i>
                      </span>

                    </div>
                    <div className="swiper-button swip-button-next" onClick={navhandleNext} style={{ alignItems: 'center' }}>
                      <span>
                        <i className="ri-arrow-right-line"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
        <Footer />
      </BrowserView>

      <MobileView>
        <MobileHeader
          Route="" PageName="" cartCount={0}
        />
        <div id="appCapsule" className="product-single">
          {spinnerLoading == true ?
            <>
              <div className="mproductSlider">
                <Swiper
                  className="mproduct-details-carousel"
                >
                  <SwiperSlide >
                    <div><Skeleton variant="text" width='100%' height={400} /></div>
                  </SwiperSlide>
                </Swiper>

              </div>
            </> :
            <div className="mproductSlider">
              <Swiper
                loop={true}
                spaceBetween={0}
                // navigation={true}
                pagination={{ clickable: true }}
                thumbs={{
                  swiper:
                    thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
                }}
                autoplay={{
                  delay: 3000000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, FreeMode, Navigation, Thumbs, Pagination]}
                className="mproduct-details-carousel"
              >
                {rowProductsData.product_video ? (
                  <SwiperSlide key={0}>
                    <video src={rowProductsData.product_video} autoPlay="autoplay" loop muted playsInline></video>
                  </SwiperSlide>
                ) : (
                  <></>
                )}
                {galleryItems
                  ? galleryItems.map((value, index) => (
                    <SwiperSlide key={index + Number(1)}>
                      <img
                        src={
                          value.original
                            ? value.original
                            : constant.DEFAULT_IMAGE
                        }
                        alt={rowProductsData.product_name}
                      />
                    </SwiperSlide>
                  ))
                  : null}
              </Swiper>
            </div>
          }
          <div className="product-details">
            <p className="tx-12 tx-gray mb-0">Dryfu</p>
            <h1 className="product-name">{rowProductsData.product_name}</h1>
            <div className="product-meta">
              {rowProductsData.product_brand_name ? (
                <>
                  Brand:
                  <span className="product-sku">
                    {rowProductsData.product_brand_name}
                  </span>
                </>
              ) : null}
            </div>
            {ReviewData && ReviewData.length > 0 &&
              rowProductsData.product_rating &&
              rowProductsData.product_rating > 0 ? (
              <div className="cratings-container mb-3">
                <StarRating numberOfStars={rowProductsData.product_rating} />
              </div>
            ) : (
              ""
            )}
            {spinnerLoading === false && (
              <>
                <div className="product-details-size mt-4">
                  {variationData.map((valueVariation, indexVariation) => {
                    if (
                      valueVariation.attributes &&
                      valueVariation.attributes.attribute_type === 1
                    ) {
                      // COLOR
                      return (
                        <div className="mt-20" key={indexVariation}>
                          <h6>
                            {valueVariation.attributes.attribute_name}:
                          </h6>
                          <ul>
                            {valueVariation.attr_terms.map(
                              (
                                valueVariationAttr,
                                indexvalueVariationAttr
                              ) => {
                                const stringIncluded = selvararray.includes(
                                  valueVariationAttr.terms_name
                                );
                                const className = stringIncluded
                                  ? "color active"
                                  : "";
                                return (
                                  <li
                                    onClick={() =>
                                      variationSelect(
                                        valueVariationAttr,
                                        indexVariation
                                      )
                                    }
                                    className={className}
                                    key={indexvalueVariationAttr}
                                  ></li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      );
                    } else if (
                      valueVariation.attributes &&
                      valueVariation.attributes.attribute_type === 2
                    ) {
                      // IMAGE
                      return (
                        <div className="mt-20" key={indexVariation}>
                          <h6>
                            {valueVariation.attributes.attribute_name}:
                          </h6>
                          <ul>
                            {valueVariation.attr_terms.map(
                              (
                                valueVariationAttr,
                                indexvalueVariationAttr
                              ) => {
                                const stringIncluded = selvararray.includes(
                                  valueVariationAttr.terms_name
                                );
                                const className = stringIncluded
                                  ? "img active"
                                  : "";
                                return (
                                  <li
                                    onClick={() =>
                                      variationSelect(
                                        valueVariationAttr,
                                        indexVariation
                                      )
                                    }
                                    className={className}
                                    key={indexvalueVariationAttr}
                                  >
                                    <img
                                      src={
                                        valueVariationAttr.variation_images !=
                                          null
                                          ? valueVariationAttr
                                            .variation_images.pti_image
                                          : constant.DEFAULT_IMAGE
                                      }
                                      alt={rowProductsData.product_name}
                                      width="100"
                                      height="100"
                                    />
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      );
                    } else if (
                      valueVariation.attributes &&
                      valueVariation.attributes.attribute_type === 3
                    ) {
                      // TEXT
                      return (
                        <div key={indexVariation}>
                          <h6>
                            {valueVariation.attributes.attribute_name}:
                          </h6>

                          <ul>
                            {valueVariation.attr_terms.map(
                              (
                                valueVariationAttr,
                                indexvalueVariationAttr
                              ) => {
                                const stringIncluded = selvararray.includes(
                                  valueVariationAttr.terms_name
                                );
                                const className = stringIncluded
                                  ? "active"
                                  : "";
                                return (
                                  <li
                                    onClick={() =>
                                      variationSelect(
                                        valueVariationAttr,
                                        indexVariation
                                      )
                                    }
                                    className={className}
                                    href="javascript:void(0)"
                                    key={indexvalueVariationAttr}
                                  >
                                    {valueVariationAttr.terms_name}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </>
            )}
            <div className="product-details-fot">
              <div className="price">
                <ins className="new-price">
                  {multiCurrency(rowProductsData.product_selling_price)}
                </ins>
                <div>
                  <del className="old-price mr-5">
                    {multiCurrency(rowProductsData.product_price)}
                  </del>
                  {discountPercentage > 0 ? (
                    <span className="off">{Math.round(discountPercentage)}% Off</span>
                  ) : null}
                </div>
              </div>
              <div className="qtyadd qtyaddBig">
                <span onClick={(e) => handleDecrease()}><i className="ri-subtract-line"></i></span>
                <span className="number">{quantity}</span>
                <span onClick={(e) => handleIncrease()}><i className="ri-add-line"></i></span>
              </div>
            </div>
            <div className="stock-text mt-3 mb-0">
              Availability:
              {Number(rowProductsData.product_type) === 0 ? (
                Number(rowProductsData.product_inventory) === 1 ? (
                  (rowProductsData.product_stock === null || Number(rowProductsData.product_stock) <= 0) ? (
                    Number(rowProductsData.product_backorder) === 0 ||
                      Number(rowProductsData.product_backorder) === 1 ? (
                      <span className="outofdtock">Out of Stock</span>
                    ) : (
                      <span className="instock">In Stock</span>
                    )
                  ) : (
                    <span className="instock">In Stock</span>
                  )
                ) : (
                  <span className="instock">In Stock</span>
                )
              ) : (rowProductsData.product_stock === null || Number(rowProductsData.product_stock) <= 0) ? (
                <span className="outofdtock">Out of Stock</span>
              ) : (
                <span className="instock">In Stock</span>
              )}
            </div>
          </div>
          <div className="divider1"></div>
          {rowProductsData.product_highlight ? (
            <>
              <div className="spacer1"></div>
              <div className="p-15">
                {rowProductsData.product_highlight ? (
                  <div className="product-highlight">
                    <div className="page-title mb-15">
                      <h2 className="mb-0">Product Highlights</h2>
                    </div>
                    <ul>
                      {rowProductsData.product_highlight
                        .split("##")
                        .map((highlightvalue, indextag) => {
                          return <li key={indextag}>{highlightvalue}</li>;
                        })}
                    </ul>
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            ""
          )}
          <div className="divider1"></div>
          <div className="maccrod">
            <Accordion defaultActiveKey={["0"]} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Description</Accordion.Header>
                <Accordion.Body>
                  <div
                    className="mdec"
                    dangerouslySetInnerHTML={{
                      __html: rowProductsData.product_description,
                    }}
                  ></div>
                </Accordion.Body>
              </Accordion.Item>
              {rowProductsData.product_tabs &&
                rowProductsData.product_tabs.length > 0 &&
                rowProductsData.product_tabs.map((value, index) => (
                  <Accordion.Item eventKey={index + Number(1)}>
                    <Accordion.Header>{value.tab_name}</Accordion.Header>
                    <Accordion.Body>
                      <div
                        className="mdec"
                        dangerouslySetInnerHTML={{
                          __html: value.tab_description,
                        }}
                      ></div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
          </div>
          <div className="page-title p-15">
            <h2 className="mb-0">Related Products</h2>
          </div>
          <div className="relproductslider">
            <Swiper {...productCarouselOptions}>
              {relatedProducts.map((subvalue, indexProduct) => {

                return (
                  <SwiperSlide key={indexProduct}>
                    <ProductBox productValue={subvalue} indexProduct={indexProduct} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className="divider2"></div>
          <div className="p-15">
            <div className="page-title mb-15">
              <h2 className="mb-0">Rating & Reviews</h2>
            </div>
            {ReviewData && ReviewData.length > 0 ? (
              <div className="reviewsec">
                <div className="avg-rating-container">
                  <mark>{rowProductsData.product_rating}</mark>
                  <div className="avg-rating">
                    <span className="avg-rating-title mb-1">
                      Average Rating
                    </span>
                    {ReviewData && ReviewData.length > 0 &&
                      rowProductsData.product_rating &&
                      rowProductsData.product_rating > 0 ? (
                      <div className="ratings-container mb-0">
                        <StarRating numberOfStars={rowProductsData.product_rating} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="ratings-list mb-4">
                  <RatingSection />
                </div>
                {setSession ? (
                  <button
                    className="btn btn-outline-primary btn-md"
                    onClick={(e) => reviewModal()}
                  >
                    Submit Review
                  </button>
                ) : (
                  <button
                    className="btn btn-outline-primary btn-md"
                    onClick={(e) => loginModal}
                  >
                    Submit Review
                  </button>
                )}
                <div className="comentlist mt-3">
                  <ul>
                    {ReviewData.map((value, index) => {
                      if (index <= 2) {
                        return (
                          <li key={index}>
                            <div className="comment">
                              <div className="comment-body">
                                {value.pr_rating && value.pr_rating > 0 ? (
                                  <div className="ratings-container mb-2">
                                    <StarRating
                                      numberOfStars={value.pr_rating}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div className="comment-rating"></div>
                                <div className="comment-user">
                                  <span className="comment-meta">
                                    by
                                    <span className="comment-name">
                                      {value.pr_title}
                                    </span>
                                    on
                                    <span className="comment-date">
                                      {moment(
                                        value.pr_created
                                      ).format("MMM D, YYYY")}
                                    </span>
                                  </span>
                                </div>
                                <div className="comment-content">
                                  <p>{value.pr_review}</p>
                                </div>
                                {value.pr_image1 && value.pr_image1.split(',').length > 0 && (
                                  <div className="comment-images">
                                    {value.pr_image1.split(',').map((imageUrl, index) => (
                                      <img key={index} src={imageUrl} alt={`Image ${index + 1}`} width={30} />
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
                {ReviewData.length > 0 ? (
                  <p
                    className="mb-0 tx-primary"
                    onClick={(e) => reviewListModal()}
                  >
                    View All Reviews
                  </p>
                ) : null}
              </div>
            ) : setSession ? (
              <button type="button" className="btn btn-outline-primary btn-md" onClick={(e) => reviewModal()}>Submit Review</button>
            ) : (
              <button type="button" className="btn btn-outline-primary btn-md" onClick={(e) => loginModal}>Submit Review</button>
            )}
          </div>
          <div className="divider2"></div>
          <div className="page-title p-15">
            <h2 className="mb-0">Recently Viewed</h2>
          </div>
          <div className="relproductslider">
            <Swiper {...productCarouselOptions}
            >
              {recentlyViewedData.map((subvalue, indexProduct) => {

                return (
                  <SwiperSlide key={indexProduct}>
                    <ProductBox productValue={subvalue} indexProduct={indexProduct} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className="mproduct-footer">
            <button
              className="btn btn-primary btn-md btn-full"
              onClick={(e) => handleAddToCart()}
            >
              <i class="ri-shopping-cart-line mr-10"></i>{contextValues.spinnerCubLoader == rowProductsData.product_id ? <Loader type="spinner-cub" bgColor={'#000000'} color={'#000000'} size={20} /> : 'Add To Cart'}
            </button>
          </div>
        </div>
      </MobileView>
      <ReviewModal rowProductsData={rowProductsData} />
      <ReviewListModal />
    </>
  );
}
export default Productdetails;
