import React, { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { BrowserView, MobileView } from "react-device-detect";
function GridBannerFirst({ indexLoop, onParentData, dataObj }) {
  const didMountRef = useRef(true);
  useEffect(() => {
    if (didMountRef.current) {
    }
    didMountRef.current = false;
  }, []);
  return (
    <>
      <BrowserView>
        {dataObj.spinnerLoading == true ? (
          <a className="addbox overlay-zoom mb-3">
            <Skeleton variant="text" width={390} height={220} />
          </a>
        ) : (
          <>
            {dataObj.home_image !== null ? (
              <a
                href={dataObj.home_image_link !== '' ? dataObj.home_image_link : 'javascript:void(0)'}
                className="addbox overlay-zoom mb-3"
              >
                <img src={dataObj.home_image} alt='Banner' />
              </a>
            ) : dataObj.home_image_url !== null ? (
              <a
                href={dataObj.home_image_link !== '' ? dataObj.home_image_link : 'javascript:void(0)'}
                className="addbox overlay-zoom mb-3"
              >
                <img src={dataObj.home_image_url} alt='Banner' />
              </a>
            ) : null}
          </>
        )}
      </BrowserView>
      <MobileView>
        {dataObj.spinnerLoading == true ? (
          <a className="addbox overlay-zoom mb-2">
            <Skeleton variant="text" width={390} height={220} />
          </a>
        ) : (
          <>
            {dataObj.home_image !== null ? (
              <a
                href={dataObj.home_image_link !== '' ? dataObj.home_image_link : 'javascript:void(0)'}
                className="addbox overlay-zoom mb-2"
              >
                <img src={dataObj.home_image} alt='Banner' />
              </a>
            ) : dataObj.home_image_url !== null ? (
              <a
                href={dataObj.home_image_link !== '' ? dataObj.home_image_link : 'javascript:void(0)'}
                className="addbox overlay-zoom mb-2"
              >
                <img src={dataObj.home_image_url} alt='Banner' />
              </a>
            ) : null}
          </>
        )}
      </MobileView>
    </>
  );
}
export default GridBannerFirst;
