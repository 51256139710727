import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'

function Blog() {
  return (
    <>
    <Header />
      <div>Blog</div>
      <Footer/>
    </>
  
  )
}

export default Blog