import React from 'react'
import Footer from '../../Components/Footer'
import Header from '../../Components/Header'

function BlogDetail() {
  return (
    <>
    <Header/>
    <div>BlogDetail</div>

    <Footer/>
    </>

  )
}

export default BlogDetail