import { useEffect, useRef, useState, useContext } from 'react';
import sessionCartData from '../Elements/cart_session_data';
import multiCurrency from '../Elements/multi_currrency';
import { addToCart, minusToCart } from '../Elements/add_to_cart';
import DataContext from '../Elements/eventContext';
import Loader from "react-js-loader";
import { ApiService } from '../Services/apiservices';
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import constant from '../Services/constant';
function CartModal() {
    const didMountRef = useRef(true);
    const navigate = useNavigate();
    const contextValues = useContext(DataContext);
    const dataArray = sessionCartData();
    const parsedCartSession = dataArray[1];
    const parsedCartSummary = dataArray[3];
    useEffect(() => {
        if (didMountRef.current) {
        }
        didMountRef.current = false;
    }, []);
    const checkoutModal = () => {
        localStorage.removeItem("COUPON_SESSION");
        localStorage.removeItem("ADDRESS_SESSION");
        checkoutOrderSummary()
    }
    const plusToCart = async (productValue) => {
        contextValues.setSpinnerCubLoader(productValue.product_id)
        const productData = {
            product_id: Number(productValue.product_id),
            product_name: productValue.product_name,
            product_slug: productValue.product_slug,
            product_image: productValue.product_image
                ? productValue.product_image
                : constant.DEFAULT_IMAGE,
            product_type: Number(productValue.product_type),
            product_price: Number(productValue.product_price),
            product_selling_price: Number(productValue.product_selling_price),
            product_discount: productValue.product_discount,
            product_variation: productValue.product_variation,
            product_category_id: productValue.product_category_id,
        };
        const updateStatus = await addToCart(productData, productValue.quantity, contextValues);
        if (updateStatus) {
            contextValues.setSpinnerCubLoader(0)
        } else {
            contextValues.setSpinnerCubLoader(0)
        }
    };
    const removeToCart = (productValue) => {
        contextValues.setSpinnerCubLoader(productValue.product_id)
        if (minusToCart(productValue, productValue.product_variation, contextValues)) {
            setTimeout(() => {
                contextValues.setSpinnerCubLoader(0)
            }, 500);
        } else {
            setTimeout(() => {
                contextValues.setSpinnerCubLoader(0)
            }, 500);
        }
    };
    const checkoutOrderSummary = () => {
        const productData = {
            sessionItemsSummary: parsedCartSession
        };
        ApiService.postData("checkOrderSummary", productData).then((res) => {
            if (res.status === 'success') {
                localStorage.removeItem("CART_SESSION");
                localStorage.setItem("CART_SESSION", JSON.stringify(res.updatedSessionData));
                contextValues.setAddressSession(dataArray[0])
                contextValues.setCartSessionData(dataArray[1])
                contextValues.setCouponSession(dataArray[2])
                contextValues.setCartSummary(dataArray[3])
                contextValues.setCartCount(dataArray[1].length)
                const hasItemWithStatusTrue = dataArray[1].some((item) => {
                    return (item.product_item_status === true);
                });
                if (!hasItemWithStatusTrue) {
                    //contextValues.setSpinnerCubLoader(1)
                    //contextValues.setToggleCouponModal(false)
                    //contextValues.setToggleCheckoutModal(!contextValues.toggleCheckoutModal)
                    cartModal()
                    navigate('/checkout')
                    //window.location.reload()
                }
            } else {
            }
        }).catch((error) => {
        });
    }
    const cartModal = () => {
        contextValues.setToggleCartModal(!contextValues.toggleCartModal)
    }

    const loginModal = () => {
        contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
    }
    return (
        <>
            <Modal show={contextValues.toggleCartModal} onHide={(e) => cartModal()} className="right cartmodal">
                {parsedCartSession.length > 0 ? (
                    <div className='cartSection'>
                        <div className='cartHeader'>
                            <h6 className='mb-0'>Shopping Cart</h6>
                            <button type="button" className="cart-close" onClick={(e) => cartModal()}><i className="ri-close-line ri-xl"></i></button>
                        </div>
                        <div className='cartScoller'>
                            {parsedCartSession.map((value, index) => {
                                return (
                                    <div className='cartProduct' key={index} >
                                        <figure className='cartProduct-media'>
                                            <a href={"/product/" + value.product_slug}>
                                                <img src={value.product_image}></img>
                                            </a>
                                        </figure>
                                        <div className='cartProduct-details'>
                                            <a className='cartProductName' href={"/product/" + value.product_slug}>{value.product_name}</a>
                                            {value.product_variation && value.product_variation.length > 0 ? <div className='cartProductSize'>{value.product_variation.join(", ")}</div> : null}
                                            <div className='cartProduct-footer'>
                                                <div className='cartProductPrice'>
                                                    <ins className="new-price">{multiCurrency(value.product_selling_price)}</ins>
                                                    <del className="old-price">{multiCurrency(value.product_price)}</del>
                                                </div>
                                                <div className='cartProductqty'>
                                                    {contextValues.spinnerCubLoader == value.product_id ? <Loader type="spinner-cub" bgColor={'#000'} color={'#000'} size={20} /> : <>
                                                        <span onClick={(e) => removeToCart(value)}><i className="ri-subtract-line"></i></span>
                                                        <span>{value.quantity}</span>
                                                        <span onClick={(e) => plusToCart(value)}><i className="ri-add-line"></i></span>
                                                    </>
                                                    }
                                                </div>
                                            </div>
                                            {value.product_item_status ? <div style={{ 'color': 'red' }}>{value.product_item_status_message}</div> : null}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className='cartFooter'>
                            <div className='d-flex mb-20 justify-content-between'>
                                <div>
                                    <h6 className='mb-1'>Estimated Total </h6>
                                    <p className='mb-0 tx-12'>Discount code to be applied at checkout.</p>
                                </div>
                                <div className='d-flex justify-content-end'>
                                    <div className='carttotle'>{parsedCartSummary && parsedCartSummary.sellingTotal ? multiCurrency(parsedCartSummary.sellingTotal) : multiCurrency(0)}</div>
                                </div>
                            </div>
                            {contextValues.userToken ? <button type='button' className="btn btn-primary btn-full btn-md" onClick={(e) => checkoutModal()}>Checkout</button> : <button type='button' className="btn btn-primary btn-full btn-md" onClick={(e) =>loginModal()}>Checkout</button>}
                        </div>
                    </div>
                ) : (
                    <div className='noimg'>
                        <img src='/images/emplty-cart-removebg-preview.png' className='wd-200 mb-20'></img>
                        <h6>No Products in Cart</h6>
                        <p className='tx-gray'>Look like you have not added anythings to you cart. Go agead & explore top categories</p>
                        <a href='/' className='btn btn-outline-primary btn-sm tx-uppercase'>Start Shopping</a>
                    </div>
                )}

            </Modal>
        </>
    );
}

export default CartModal;
