import React, { useEffect, useRef, useState, useContext } from 'react';
import DataContext from '../Elements/eventContext'
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import constant from '../Services/constant';
import { ApiService } from '../Services/apiservices';
import Modal from 'react-bootstrap/Modal';
function LocationModal() {
    const didMountRef = useRef(true);
    const contextValues = useContext(DataContext);
    const [isLoading, setIsLoading] = useState(false);
    const [showManualInput, setShowManualInput] = useState(false);
    const [address, setAddress] = useState("");
    const { suggestions: { status, data }, setValue, } = usePlacesAutocomplete({ requestOptions: { componentRestrictions: { country: 'IN' } }, });
    const [locationError, setLocationError] = useState("");
    const [deliveryLocationData, setDeliveryLocationData] = useState({});
    useEffect(() => {
        if (didMountRef.current) {
            const storedLocation = JSON.parse(localStorage.getItem("DELIVERY_LOCATION"));
            if (storedLocation && storedLocation.user_lng) {
                setDeliveryLocationData(storedLocation);
            }
        }
        didMountRef.current = false;
    }, []);

    const fetchData = async () => {
        try {
            setIsLoading(true)
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    async (position) => {
                        geocodelocation(position.coords.latitude, position.coords.longitude);
                    },
                    (error) => {
                        // Error callback
                        setIsLoading(false);
                        switch (error.code) {
                            case error.PERMISSION_DENIED:
                                setLocationError("We do not have permission to determine your location. Please enter manually.");
                                break;
                            case error.POSITION_UNAVAILABLE:
                                setLocationError("Location information is unavailable.");
                                break;
                            case error.TIMEOUT:
                                setLocationError("The request to get user location timed out.");
                                break;
                            default:
                                setLocationError("An unknown error occurred.");
                                break;
                        }
                    }
                );
            } else {
                setIsLoading(false)
                setLocationError("We do not have permission to determine your location. Please enter manually.");
            }
        } catch (error) {
            setLocationError(error);
            setIsLoading(false)
        }
    };

    const handleSelect = async (value) => {
        try {
            setValue([])
            setAddress("");
            const results = await getGeocode({ address: value });
            const { lat, lng } = await getLatLng(results[0]);
            geocodelocation(lat, lng);
        } catch (error) {
            setLocationError(error);
        }
    };

    const geocodelocation = async (lat, lng) => {
        const mapApiUrl = `geocode/json?latlng=${lat},${lng}&key=${constant.GOOGLE_MAP_KEY}`;
        try {
            setIsLoading(true)
            const response = await ApiService.fetchGoogleData(mapApiUrl);
            if (response.status === "OK") {
                /* const productData = {
                    ua_lat: lat,
                    ua_lng: lng,
                };
                ApiService.postData("checkServiceable", productData).then((res) => {
                    if (res.status === 'success') {
                        const updateAddressObj = {
                            full_address: response.results[0].formatted_address,
                            city: "",
                            state: "",
                            country: "",
                            postal_code: "",
                            user_lat: response.results[0].geometry.location.lat,
                            user_lng: response.results[0].geometry.location.lng,
                        };
                        response.results[0].address_components.forEach((component) => {
                            component.types.forEach((type) => {
                                switch (type) {
                                    case "locality":
                                        updateAddressObj.city = component.long_name;
                                        break;
                                    case "administrative_area_level_1":
                                        updateAddressObj.state = component.long_name;
                                        break;
                                    case "country":
                                        updateAddressObj.country = component.long_name;
                                        break;
                                    case "postal_code":
                                        updateAddressObj.postal_code = component.long_name;
                                        break;
                                    default:
                                        break;
                                }
                            });
                        });
                        setIsLoading(false)
                        localStorage.setItem("DELIVERY_LOCATION", JSON.stringify(updateAddressObj));
                        window.location.reload();
                    } else {
                        setLocationError(res.message);
                        setTimeout(() => {
                            setLocationError('')
                            setIsLoading(false)
                        }, 1000);
                    }
                }).catch((error) => {
                    setLocationError(error);
                }); */
                const updateAddressObj = {
                    full_address: response.results[0].formatted_address,
                    city: "",
                    state: "",
                    country: "",
                    postal_code: "",
                    user_lat: response.results[0].geometry.location.lat,
                    user_lng: response.results[0].geometry.location.lng,
                };
                response.results[0].address_components.forEach((component) => {
                    component.types.forEach((type) => {
                        switch (type) {
                            case "locality":
                                updateAddressObj.city = component.long_name;
                                break;
                            case "administrative_area_level_1":
                                updateAddressObj.state = component.long_name;
                                break;
                            case "country":
                                updateAddressObj.country = component.long_name;
                                break;
                            case "postal_code":
                                updateAddressObj.postal_code = component.long_name;
                                break;
                            default:
                                break;
                        }
                    });
                });
                setIsLoading(false)
                localStorage.setItem("DELIVERY_LOCATION", JSON.stringify(updateAddressObj));
                window.location.reload();
            }
        } catch (error) {
            setLocationError(error);
        }
    }
    const locationModal = () => {
        contextValues.setShowLocationModal(!contextValues.showLocationModal)
    }
    const toggleManualSearch = () => {
        setShowManualInput(!showManualInput)
    }
    return (
        <>
            <Modal className='locModal' show={contextValues.showLocationModal} onHide={(e) => locationModal()} backdrop="static">
                {deliveryLocationData.user_lat && <button type="button" className="btn-close" aria-label="Close" onClick={(e) => locationModal()} style={{ 'z-index': '99' }}></button>}
                <div className='locationModalcontent'>
                    <h6>Set Your Delivery Location</h6>
                    <p>To deliver as quickly as possible, we would like your current location</p>
                    <div>
                        <button type='button' className='btn btn-md btn-primary mr-10' onClick={fetchData}>
                            {isLoading ? (<img src="/images/loder01.gif" width="60px" height="11px" />) : ("Use Current Location")}
                        </button>
                        <button type='button' className='btn btn-md btn-outline-primary'
                            onClick={() => toggleManualSearch()}
                        > {isLoading ? (<img src="/images/loder01.gif" width="60px" height="11px" />) : ("Type Manually")}</button>
                    </div>
                    {locationError && (<div className="alert alert-danger" role="alert">
                        {locationError}
                    </div>)}

                    {showManualInput && (
                        <div className='lcsearch'>
                            <div className='lcsearchinput'>
                                <input type="text" placeholder="Search Delivery Location..."
                                    onChange={(e) => {
                                        setAddress(e.target.value);
                                        setValue(e.target.value);
                                        setLocationError("");
                                    }} />
                            </div>
                            <div className='lcsearcharea'>
                                <ul>
                                    {status === "OK" && data.map(({ id, description }) => (
                                        <li key={id} onClick={() => handleSelect(description)}>
                                            {description}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </Modal>
        </>
    )
}

export default LocationModal