import React, { useEffect, useState, useRef, useContext, useCallback } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { ApiService } from "../../Components/Services/apiservices";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import constant from "../../Components/Services/constant";
import CategoryWiseProducts from "../../Components/Elements/category_wise_products";
import TagWiseProducts from "../../Components/Elements/tag_wise_products";
import HomeTopBanner from "../../Components/Elements/home_top_banner";
import GridBannerFirst from "../../Components/Elements/grid_banner_first";
import { Swiper, SwiperSlide } from "swiper/react";
import CategoryHtml from "../../Components/Elements/category_html";
import VideoProductsHtml from "../../Components/Elements/video_products";
function Home() {
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const didMountRef = useRef(true);
  const [sectionData, setSectionData] = useState([])
  const [mobileSectionData, setMobileSectionData] = useState([])
  const [mediaImageUrl, setMediaImageUrl] = useState("")

  useEffect(() => {
    if (didMountRef.current) {
      getDashboardData();
    }
    didMountRef.current = false;
  }, []);
  const getDashboardData = () => {
    ApiService.fetchData("dashboard").then((res) => {
      if (res.status == "success") {
        setMediaImageUrl(res.mediaImageUrl)
        setSectionData(res.resAppearanceHomeData)
        setMobileSectionData(res.resAppearanceHomeMobileData)
        setSpinnerLoading(false);
      } else {
        setSpinnerLoading(false);
      }
    });
  };
  const handleCartCount = (status) => {
  };
  const HomesliderRef = useRef(null);
  const HomehandlePrev = useCallback(() => {
    if (!HomesliderRef.current) return;
    HomesliderRef.current.swiper.slidePrev();
  }, []);

  const HomehandleNext = useCallback(() => {
    if (!HomesliderRef.current) return;
    HomesliderRef.current.swiper.slideNext();
  }, [])
  const browserHomeRef = useRef(null);
  const browHomehandlePrev = useCallback(() => {
    if (!browserHomeRef.current) return;
    browserHomeRef.current.swiper.slidePrev();
  }, []);

  const browHomehandleNext = useCallback(() => {
    if (!browserHomeRef.current) return;
    browserHomeRef.current.swiper.slideNext();
  }, [])
  return (
    <>
      <Header />
      <HomeTopBanner />
      <BrowserView>
        {sectionData.length > 0 && (
          sectionData.map((valueSection, indexSection) => (
            <section className={valueSection.home_top_spaced + " " + valueSection.home_bottom_spaced} key={indexSection}>
              <div className="container" key={indexSection}>
                <div className={"row  " + valueSection.home_grid}>
                  {valueSection.has_many_home_inner.length > 0 &&
                    valueSection.has_many_home_inner.map((valueRowInner, indexRowInner) => (
                      <><div className={"col-lg-" + valueRowInner.home_inn_structure_type} key={indexRowInner}>
                        {valueRowInner.has_many_home_details.length > 0 &&
                          valueRowInner.has_many_home_details.map((valueRow, indexRow) => {
                            let dataObj;
                            if (valueRow.home_type === 1) {
                              if (valueRow.home_layout_for_type === 1) {
                                dataObj = {
                                  'id': valueRow.home_cat_id,
                                  'home_product_name': valueRow.home_product_name,
                                  'home_column': valueRow.home_column,
                                  'home_display_type': valueRow.home_display_type,
                                  'home_no_items': valueRow.home_no_items,
                                  'home_col_grid': 'cols-xl-',
                                };
                                return (
                                  <CategoryWiseProducts
                                    key={indexRow}
                                    onParentData={handleCartCount}
                                    dataObj={dataObj}
                                  />
                                );
                              } else {
                                dataObj = {
                                  'id': valueRow.home_tag_id,
                                  'home_product_name': valueRow.home_product_name,
                                  'home_column': valueRow.home_column,
                                  'home_display_type': valueRow.home_display_type,
                                  'home_no_items': valueRow.home_no_items,
                                  'home_col_grid': 'cols-xl-',
                                };
                                return (
                                  <TagWiseProducts
                                    key={indexRow}
                                    onParentData={handleCartCount}
                                    dataObj={dataObj}
                                  />
                                );
                              }
                            } else if (valueRow.home_type === 2) {
                              dataObj = {
                                'id': valueRow.home_tag_id,
                                'home_product_name': valueRow.home_cat_title,
                                'home_column': valueRow.home_column,
                                'home_display_type': valueRow.home_display_type,
                                'home_no_items': valueRow.home_no_items,
                                'home_col_grid': 'cols-xl-',
                                'home_cat_multi_id': valueRow.home_cat_multi_id,
                              };
                              return (
                                <CategoryHtml
                                  key={indexRow}
                                  onParentData={handleCartCount}
                                  dataObj={dataObj}
                                />
                              );
                            } else if (valueRow.home_type === 3) {
                              return (
                                <p key={indexRow}></p>
                              );
                            } else if (valueRow.home_type === 4) {
                              return (
                                <div key={indexRow}>
                                  <p>{valueRow.home_text_title}</p>
                                  <div dangerouslySetInnerHTML={{ __html: valueRow.home_text_description }}></div>
                                </div>
                              );
                            } else if (valueRow.home_type === 5) {
                              dataObj = {
                                'home_image_link': valueRow.home_image_link,
                                'home_image_url': valueRow.home_image_url,
                                'home_image': valueRow.home_image,
                                'spinnerLoading': spinnerLoading,
                              };
                              return (
                                <GridBannerFirst
                                  key={indexRow}
                                  onParentData={handleCartCount}
                                  dataObj={dataObj}
                                />
                              );
                            } else if (valueRow.home_type === 6) {
                              dataObj = {
                                'home_image_link': valueRow.home_image_link,
                                'home_image_url': valueRow.home_image_url,
                                'home_image': valueRow.home_image,
                                'spinnerLoading': spinnerLoading,
                              };
                              return (
                                <div className="swipergallery" key={indexRow}>
                                  <Swiper
                                    loop={true}
                                    spaceBetween={valueRow.home_space_between_slide}
                                    navigation={{
                                      nextEl: ".swiper-button-next",
                                      prevEl: ".swiper-button-prev",
                                    }}
                                    breakpoints={{
                                      0: {
                                        slidesPerView: valueRow.home_column,
                                      },
                                      600: {
                                        slidesPerView: valueRow.home_column,
                                      },
                                      1000: {
                                        slidesPerView: valueRow.home_column,
                                      },
                                    }}
                                    ref={browserHomeRef}
                                  >
                                    {valueRow.has_many_gallery && valueRow.has_many_gallery.length > 0 && valueRow.has_many_gallery.map((galleryValue, indexgallery) => {
                                      return (
                                        <SwiperSlide key={indexgallery}>
                                          <div key={indexgallery}>
                                            <img src={galleryValue.home_gallery_image_url != null ? mediaImageUrl + galleryValue.home_gallery_image_url : constant.DEFAULT_IMAGE} alt='gallery' title='gallery'></img>
                                          </div>
                                        </SwiperSlide>
                                      );
                                    })}
                                  </Swiper>
                                  <div className='swiper-buttons__wrapper'>
                                    <div className='container'>
                                      <div className='swiper-buttons__box'>
                                        <div className="swiper-button swiper-button-prev" style={{ alignItems: 'center' }} onClick={browHomehandlePrev} >
                                          <span><svg width="27" height="22" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2 14L26 14" stroke="currentColor" strokeWidth="3" strokeLinecap="square"></path>
                                            <path d="M17.6514 3L29.6514 14L17.6514 25" stroke="currentColor" strokeWidth="3" strokeLinecap="square"></path>
                                          </svg>
                                          </span>
                                        </div>
                                        <div className="swiper-button swiper-button-next" onClick={browHomehandleNext} style={{ alignItems: 'center' }}>
                                          <span><svg width="27" height="22" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2 14L26 14" stroke="currentColor" strokeWidth="3" strokeLinecap="square"></path>
                                            <path d="M17.6514 3L29.6514 14L17.6514 25" stroke="currentColor" strokeWidth="3" strokeLinecap="square"></path>
                                          </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              );
                            } else if (valueRow.home_type === 7) {
                              dataObj = {
                                'id': valueRow.home_tag_id,
                                'home_product_name': valueRow.home_cat_title,
                                'home_column': valueRow.home_column,
                                'home_display_type': valueRow.home_display_type,
                                'home_no_items': valueRow.home_no_items,
                                'home_col_grid': 'cols-xl-',
                                'home_cat_multi_id': valueRow.home_cat_multi_id,
                              };
                              return (
                                <VideoProductsHtml
                                  key={indexRow}
                                  onParentData={handleCartCount}
                                  dataObj={dataObj}
                                />
                              );
                            }
                          })}
                      </div></>
                    ))}
                </div>
              </div>
            </section>
          ))
        )}
      </BrowserView>
      <MobileView>
        {mobileSectionData.length > 0 && (
          mobileSectionData.map((valueSection, indexSection) => (
            <section className={valueSection.home_top_spaced + " " + valueSection.home_bottom_spaced} key={indexSection}>
              <div className="container">
                <div className={"row  " + valueSection.home_grid}>
                  {valueSection.has_many_home_inner.length > 0 &&
                    valueSection.has_many_home_inner.map((valueRowInner, indexRowInner) => (
                      <><div className={"col-" + valueRowInner.home_inn_structure_type} key={indexRowInner}>
                        {valueRowInner.has_many_home_details.length > 0 &&
                          valueRowInner.has_many_home_details.map((valueRow, indexRow) => {
                            let dataObj;
                            if (valueRow.home_type === 1) {
                              if (valueRow.home_layout_for_type === 1) {
                                dataObj = {
                                  'id': valueRow.home_cat_id,
                                  'home_product_name': valueRow.home_product_name,
                                  'home_column': valueRow.home_column,
                                  'home_display_type': valueRow.home_display_type,
                                  'home_no_items': valueRow.home_no_items,
                                  'home_col_grid': 'cols-',
                                };
                                return (

                                  <CategoryWiseProducts
                                    key={indexRow}
                                    onParentData={handleCartCount}
                                    dataObj={dataObj}
                                  />
                                );
                              } else {
                                dataObj = {
                                  'id': valueRow.home_tag_id,
                                  'home_product_name': valueRow.home_product_name,
                                  'home_column': valueRow.home_column,
                                  'home_display_type': valueRow.home_display_type,
                                  'home_no_items': valueRow.home_no_items,
                                  'home_col_grid': 'cols-',
                                };
                                return (
                                  <TagWiseProducts
                                    key={indexRow}
                                    onParentData={handleCartCount}
                                    dataObj={dataObj}
                                  />
                                );
                              }
                            } else if (valueRow.home_type === 2) {
                              dataObj = {
                                'id': valueRow.home_tag_id,
                                'home_product_name': valueRow.home_cat_title,
                                'home_column': valueRow.home_column,
                                'home_display_type': valueRow.home_display_type,
                                'home_no_items': valueRow.home_no_items,
                                'home_col_grid': 'cols-',
                                'home_cat_multi_id': valueRow.home_cat_multi_id,
                              };
                              return (
                                <CategoryHtml
                                  key={indexRow}
                                  onParentData={handleCartCount}
                                  dataObj={dataObj}
                                />
                              );
                            } else if (valueRow.home_type === 3) {
                              return (
                                <div className={"col-lg-" + valueRow.home_layout_type} key={indexRow}>
                                  <p key={indexRow}></p>
                                </div>
                              );
                            } else if (valueRow.home_type === 4) {
                              return (
                                <div key={indexRow}>
                                  <p>{valueRow.home_text_title}</p>
                                  <div dangerouslySetInnerHTML={{ __html: valueRow.home_text_description }}></div>
                                </div>
                              );
                            } else if (valueRow.home_type === 5) {
                              dataObj = {
                                'home_image_link': valueRow.home_image_link,
                                'home_image_url': valueRow.home_image_url,
                                'home_image': valueRow.home_image,
                                'spinnerLoading': spinnerLoading,
                              };
                              return (
                                <GridBannerFirst
                                  key={indexRow}
                                  onParentData={handleCartCount}
                                  dataObj={dataObj}
                                />
                              );
                            } else if (valueRow.home_type === 6) {
                              dataObj = {
                                'home_image_link': valueRow.home_image_link,
                                'home_image_url': valueRow.home_image_url,
                                'home_image': valueRow.home_image,
                                'spinnerLoading': spinnerLoading,
                              };
                              return (
                                <>
                                  <div className="swipergallery">
                                    <Swiper
                                      loop={true}
                                      spaceBetween={valueRow.home_space_between_slide}
                                      navigation={{
                                        nextEl: ".swiper-button-next",
                                        prevEl: ".swiper-button-prev",
                                      }}
                                      breakpoints={{
                                        0: {
                                          slidesPerView: valueRow.home_column,
                                        },
                                        600: {
                                          slidesPerView: valueRow.home_column,
                                        },
                                        1000: {
                                          slidesPerView: valueRow.home_column,
                                        },
                                      }}
                                      ref={HomesliderRef}>
                                      {valueRow.has_many_gallery && valueRow.has_many_gallery.length > 0 && valueRow.has_many_gallery.map((galleryValue, indexgallery) => {
                                        return (
                                          <SwiperSlide key={indexgallery}>
                                            <div key={indexgallery}>
                                              <img src={galleryValue.home_gallery_image_url != null ? mediaImageUrl + galleryValue.home_gallery_image_url : constant.DEFAULT_IMAGE} alt='gallery' title='gallery'></img>
                                            </div>
                                          </SwiperSlide>
                                        );
                                      })}
                                    </Swiper>
                                  </div>
                                  <div className='swiper-buttons__wrapper'>
                                                <div className='container'>
                                                    <div className='swiper-buttons__box'>
                                                        <div className="swiper-button swiper-button-prev" style={{ alignItems: 'center' }} onClick={HomehandlePrev} >
                                                            <span><svg width="27" height="22" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M2 14L26 14" stroke="currentColor" strokeWidth="3" strokeLinecap="square"></path>
                                                                <path d="M17.6514 3L29.6514 14L17.6514 25" stroke="currentColor" strokeWidth="3" strokeLinecap="square"></path>
                                                            </svg>
                                                            </span>
                                                        </div>
                                                        <div className="swiper-button swiper-button-next" onClick={HomehandleNext} style={{ alignItems: 'center' }}>
                                                            <span><svg width="27" height="22" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M2 14L26 14" stroke="currentColor" strokeWidth="3" strokeLinecap="square"></path>
                                                                <path d="M17.6514 3L29.6514 14L17.6514 25" stroke="currentColor" strokeWidth="3" strokeLinecap="square"></path>
                                                            </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                </>

                              );
                            } else if (valueRow.home_type === 7) {
                              dataObj = {
                                'id': valueRow.home_tag_id,
                                'home_product_name': valueRow.home_cat_title,
                                'home_column': valueRow.home_column,
                                'home_display_type': valueRow.home_display_type,
                                'home_no_items': valueRow.home_no_items,
                                'home_col_grid': 'cols-xl-',
                                'home_cat_multi_id': valueRow.home_cat_multi_id,
                              };
                              return (
                                <VideoProductsHtml
                                  key={indexRow}
                                  onParentData={handleCartCount}
                                  dataObj={dataObj}
                                />
                              );
                            }
                          })}
                      </div></>
                    ))}
                </div>
              </div>
            </section>
          ))
        )}
      </MobileView>
      <Footer />
    </>
  );
}
export default Home;
