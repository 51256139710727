import React, { useEffect, useState, useRef, useContext } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import AccountSidebar from './account_sidebar'
import { BrowserView, MobileView } from 'react-device-detect'
import SpinnerLoader from "../../Components/Elements/spinner_loader";
import { useNavigate } from "react-router-dom";
import MobHeader from "../../Components/Header/mobheader";
import DataContext from "../../Components/Elements/eventContext";
import Skeleton from "react-loading-skeleton";
function AccountOverview() {
  const contextValues = useContext(DataContext);
  const didMountRef = useRef(true);
  const navigate = useNavigate();
  const [skeletonStatus, setSkeletonStatus] = useState(false);
  useEffect(() => {
    if (didMountRef.current) {
      setSkeletonStatus(true)
      setTimeout(() => {
        setSkeletonStatus(false)
      }, 500);
    }
    didMountRef.current = false;
  }, []);

  const logoutProcess = () => {
    const confirmed = window.confirm("Are you sure you want to log out");
    if (confirmed) {
      ApiService.fetchData("logout").then((res) => {
        if (res.status === "success") {
          localStorage.removeItem("USER_TOKEN")
          navigate('/')
        }
      })
    }
  }

  const redirectTo = (Route) => {
    navigate(Route);
  };

  const shareapp = () => {
    window.open(contextValues.settingData.admin_playstore_user, '_blank');
  }

  return (
    <>
      {/* {spinnerLoading && <SpinnerLoader />} */}
      <BrowserView>
        <Header />
        <div className="subheader">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Account Overview</li>
                  </ol>
                </nav>

              </div>
            </div>
          </div>
        </div>

        <section className="section-gap-medium">
          <div className="container">
            <div className="row">
              <AccountSidebar/>
              <div className="col-lg-9">
                <div className="row g-3">
                  <div className="col-lg-6">
                    <a href="/account/profile" className="aobox">
                      <img src="/images/account/profile.png" className="mb-3" />
                      <h6>Profile</h6>
                      <p className="mb-0">Manage your account information & password</p>
                    </a>
                  </div>
                  <div className="col-lg-6">
                    <a href="/account/address" className="aobox">
                      <img src="/images/account/pin.png" className="mb-3" />
                      <h6>Address</h6>
                      <p className="mb-0">Saved addresses for hassle-free checkout</p>
                    </a>
                  </div>
                  <div className="col-lg-6">
                    <a href="/account/orders" className="aobox">
                      <img src="/images/account/order.png" className="mb-3" />
                      <h6>My Orders</h6>
                      <p className="mb-0">Check your order status & track here</p>
                    </a>
                  </div>

                  {/* <div className="col-lg-4">
                    <a href="/account/wallet" className="aobox">
                      <img src="/images/account/wallet.png" className="mb-3" />
                      <h6>Wallet</h6>
                      <p className="mb-0">Help regarding recent purchase & other</p>
                    </a>
                  </div> */}

                  <div className="col-lg-6">
                    <a href="/account/help-and-support" className="aobox">
                      <img src="/images/account/helpsupport.png" className="mb-3" />
                      <h6>Help & Support</h6>
                      <p className="mb-0">Help regarding recent purchase & other</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </BrowserView>

      <MobileView>
        <MobHeader PageName="My Account" Route="" />
        <div id="appCapsule">
          <div className="p-15">
            {skeletonStatus ? <>
              <div className="profileBox mb-15">
                <div>
                  <h6 className="mb-1"><Skeleton width={150} /></h6>
                  <p className="mb-0 mt-0"><Skeleton width={100} /></p>
                </div>
                <div className="ml-auto">
                  <Skeleton circle={true} height={50} width={50} />
                </div>
              </div>
              <div className="listbox mb-15">
                <ul>
                  <li>
                    <div class="listboxIcon">
                    <Skeleton circle={true} height={25} width={25} />
                    </div>
                    <span class="tx-14"><Skeleton width={230} /></span>
                  </li>
                  <li>
                    <div class="listboxIcon">
                    <Skeleton circle={true} height={25} width={25} />                    
                    </div>
                    <span class="tx-14"><Skeleton width={230} /></span>
                  </li>
                </ul>
              </div>
              <div className="listbox mb-15">
                <ul>
                  <li>
                    <div class="listboxIcon">
                    <Skeleton circle={true} height={25} width={25} />                    
                    </div>
                    <span class="tx-14"><Skeleton width={230} /></span>
                  </li>
                  <li>
                    <div class="listboxIcon">
                    <Skeleton circle={true} height={25} width={25} />                    
                    </div>
                    <span class="tx-14"><Skeleton width={230} /></span>
                  </li>
                </ul>
              </div>
              <button class="btn btn-full btn-defult btn-md"><Skeleton width={230} /></button>
            </> : <>
              <div className="profileBox mb-15" onClick={() => redirectTo("/account/profile")}>
                <div>
                  <h6 className="mb-1">Hi! {contextValues.rowUserData.user_fname}</h6>
                  <p className="mb-0 mt-0">+91 {contextValues.rowUserData.user_mobile}</p>
                </div>
                <div className="ml-auto">
                  <img src="/images/04.png" className="wd-50" />
                </div>
              </div>
              <div className="listbox mb-15">
                <ul>
                  <li onClick={() => redirectTo("/account/orders")}>
                    <div class="listboxIcon">
                      <img src="/images/01.png" />
                    </div>
                    <span class="tx-14">My Orders</span>
                    <i class="ri-arrow-right-s-line ri-xl ml-auto"></i>
                  </li>
                  <li onClick={() => redirectTo("/account/address")} >
                    <div class="listboxIcon">
                      <img src="/images/02.png" />
                    </div>
                    <span class="tx-14">Address Book</span>
                    <i class="ri-arrow-right-s-line ri-xl ml-auto"></i>
                  </li>
                </ul>
              </div>
              <div className="listbox mb-15">
                <ul>
                  <li onClick={() => redirectTo("/account/help-and-support")}>
                    <div class="listboxIcon">
                      <img src="/images/08.png" />
                    </div>
                    <span class="tx-14">Help & Support</span>
                    <i class="ri-arrow-right-s-line ri-xl ml-auto"></i>
                  </li>
                  <li onClick={shareapp} >
                    <div class="listboxIcon">
                      <img src="/images/06.png" />
                    </div>
                    <span class="tx-14">Share & Earn</span>
                    <i class="ri-arrow-right-s-line ri-xl ml-auto"></i>
                  </li>
                </ul>
              </div>
              <button class="btn btn-full btn-defult btn-md" onClick={logoutProcess}>Logout form Dryfu</button>
            </>}
          </div>
        </div>
      </MobileView>
    </>
  )
}

export default AccountOverview