import React, { useEffect, useRef, useState } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { ApiService } from '../../Components/Services/apiservices'
import { useParams } from "react-router-dom";

function Cancel() {
  const didMountRef = useRef(true);
  const { orderId } = useParams();
  const [pageContent, setPageContent] = useState("");
  useEffect(() => {
    if (didMountRef.current) { 
    }
    didMountRef.current = false;
  }, []);
  return (
    <>
      <Header />
       <div>Order Cancelled</div>
      <Footer />
    </>

  )
}

export default Cancel