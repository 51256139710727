import React, { useEffect, useState, useRef, useContext } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import DataContext from '../../Components/Elements/eventContext';
import { ApiService } from "../../Components/Services/apiservices";
import { BrowserView, MobileView } from "react-device-detect";
import AccountSidebar from "./account_sidebar";
import CheckoutAddressModal from '../../Components/Modals/checkout_address_modal';
import MobHeader from "../../Components/Header/mobheader";
import Loader from "react-js-loader";
function Address() {
  const didMountRef = useRef(true);
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const contextValues = useContext(DataContext);
  useEffect(() => {
    if (didMountRef.current) {
      getUserAddress();
    }
    didMountRef.current = false;
  }, []);

  const getUserAddress = () => {
    ApiService.fetchData("get-user-address").then((res) => {
      if (res.status === "success") {
        contextValues.setUserAddressList(res.resUserAddress);
        setTimeout(() => {
          setSpinnerLoading(false);
        }, 500);
      } else {
        setTimeout(() => {
          setSpinnerLoading(false);
        }, 500);
      }
    });
  };
  const deleteaddress = (value) => {
    if (window.confirm("Are you sure?")) {
      const dataString = {
        addrid: value,
      };
      ApiService.postData("removeAddress", dataString).then((res) => {
        if (res.status == "success") {
          getUserAddress();
        }
      });
    } else {
    }
  };

  const editaddress = (value) => {
    contextValues.newAddress.ua_id = value.ua_id;
    contextValues.newAddress.ua_house_no = value.ua_house_no;
    contextValues.newAddress.ua_area = value.ua_area;
    contextValues.newAddress.ua_address_type = value.ua_address_type;
    contextValues.newAddress.ua_name = value.ua_name;
    contextValues.newAddress.ua_email = value.ua_email;
    contextValues.newAddress.ua_address_type_other = value.ua_address_type_other;
    contextValues.deliveryLocationData.full_address = value.ua_complete_address;
    contextValues.deliveryLocationData.city = value.ua_city_name;
    contextValues.deliveryLocationData.state = value.ua_state_name;
    contextValues.deliveryLocationData.country = value.ua_country_name;
    contextValues.deliveryLocationData.postal_code = value.ua_pincode;
    contextValues.deliveryLocationData.user_lat = parseFloat(value.ua_lat);
    contextValues.deliveryLocationData.user_lng = parseFloat(value.ua_lng);

    contextValues.setToggleCheckoutAddressModal(!contextValues.toggleCheckoutAddressModal)
  };

  const checkoutAddressModal = () => {
    contextValues.newAddress.ua_id = 0;
    contextValues.newAddress.ua_house_no = '';
    contextValues.newAddress.ua_area = '';
    contextValues.newAddress.ua_address_type = 'Home';
    contextValues.newAddress.ua_name = '';
    contextValues.newAddress.ua_email = '';
    contextValues.newAddress.ua_address_type_other = '';
    const storedLocation = JSON.parse(localStorage.getItem("DELIVERY_LOCATION"));
    if (storedLocation && storedLocation.user_lng) {
      contextValues.setDeliveryLocationData(storedLocation);
    }
    contextValues.setToggleCheckoutAddressModal(!contextValues.toggleCheckoutAddressModal)
  }
  return (
    <>
      <BrowserView>
        <Header state="inner-header" />
        <div className="subheader">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">My Address</li>
                  </ol>
                </nav>

              </div>
            </div>
          </div>
        </div>
        <section className="section-gap-medium">
          <div className="container">
            <div className="row">
              <AccountSidebar />
              <div className="col-lg-9">
                <div className="acpanel">
                  <div className="acpanel-header">
                    <h4>My Address</h4>
                  </div>

                  <div className="acpanel-body">
                    {spinnerLoading ? (
                      <Loader type="spinner-cub" bgColor={'#1b8057'} color={'#1b8057'} size={50} />
                    ) : <>
                      {contextValues.userAddressList.length > 0 ? (
                        <>
                          <div className="row">
                            {contextValues.userAddressList.map((value, index) => (
                              <div className="col-lg-6" key={index}>
                                <div className="addressbox mb-2">
                                  <div className="addressbox-body">
                                    <h6 className="mb-1">
                                      {value.ua_name}
                                      {value.ua_default_address == 1
                                        ? "(Default)"
                                        : ""}
                                    </h6>
                                    <p className="mb-1">
                                      {value.ua_house_no}, {value.ua_area},
                                      {value.ua_city_name},
                                      {value.ua_state_name} {value.ua_pincode}
                                    </p>
                                    <p className="mb-0">
                                      Mobile No: {value.ua_mobile}
                                    </p>
                                    <div className="addressbox-type">
                                      {value.ua_address_type == "Other"
                                        ? value.ua_address_type_other
                                        : value.ua_address_type}
                                    </div>
                                  </div>
                                  <div className="addressbox-footer">
                                    <a
                                      href="javascript:;"
                                      onClick={(e) =>
                                        editaddress(value)
                                      }
                                    >
                                      Edit
                                    </a>
                                    <a
                                      href="javascript:;"
                                      onClick={(e) =>
                                        deleteaddress(value.ua_id)
                                      }
                                    >
                                      Delete
                                    </a>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>

                          <div
                            className="btn btn-outline-primary btn-md mt-2"
                            onClick={(e) => checkoutAddressModal()}
                          >
                            Add New Address
                          </div>
                        </>
                      ) : (
                        <div className="noimg text-center">
                          <img
                            src="/images/noaddress.png"
                            style={{ width: "250px" }}
                            className="mb-3"
                          />
                          <h6>Save Your Address Now!</h6>
                          <p>
                            Add your home and office addresses and enjoy
                            faster checkout
                          </p>
                          <div
                            className="btn btn-outline-primary btn-md mt-2 ssss"
                            onClick={(e) => checkoutAddressModal()}
                          >
                            Add New Address
                          </div>
                        </div>
                      )}
                    </>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </BrowserView>
      <MobileView>
        <MobHeader PageName="Address Book" Route="account/account-overview" />
        <div id="appCapsule">

          {spinnerLoading ? (
            <Loader type="spinner-cub" bgColor={'#1b8057'} color={'#1b8057'} size={50} />
          ) : <>
            <div className="addressfooterbottom">
              <button
                type="button"
                className="btn btn-outline-primary btn-md btn-full"
                onClick={(e) => checkoutAddressModal()}
              >
                Add New Address
              </button>
            </div>
            {contextValues.userAddressList.length > 0 && !spinnerLoading ? (
              contextValues.userAddressList.map((value, index) => (
                <div key={index}>
                  <div className="addressbox mb-2">
                    <div className="addressbox-body">
                      <h6 className="mb-1">
                        {value.ua_name}{" "}
                        {value.ua_default_address === 1 ? "(Default)" : ""}
                      </h6>
                      <p className="mb-1">
                        {value.ua_house_no}, {value.ua_area},{" "}
                        {value.ua_city_name},{" "}
                        {value.ua_state_name} {value.ua_pincode}
                      </p>
                      <p className="mb-0">Mobile No: {value.ua_mobile}</p>
                      <div className="addressbox-type">
                        {value.ua_address_type === "Other"
                          ? value.ua_address_type_other
                          : value.ua_address_type}
                      </div>
                    </div>
                    <div className="addressbox-footer">
                      <a href="javascript:;" onClick={(e) => editaddress(value)}>
                        Edit
                      </a>
                      <a href="#" onClick={(e) => deleteaddress(value.ua_id)}>
                        Delete
                      </a>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <>
                <div className="addressfooterbottom">
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-md btn-full"
                    onClick={(e) => checkoutAddressModal()}
                  >
                    Add New Address
                  </button>
                </div>
                <div className="noimg text-center">
                  <img
                    src="/images/noaddress.png"
                    style={{ width: "250px" }}
                    className="mb-3"
                    alt="No Address"
                  />
                  <h6>Save Your Address Now!</h6>
                  <p>Add your home and office addresses and enjoy faster checkout</p>
                </div>
              </>
            )}
          </>}
        </div>
      </MobileView>
      <CheckoutAddressModal />
    </>
  );
}
export default Address;
