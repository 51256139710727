import React, { useEffect, useRef, useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiService } from '../../Components/Services/apiservices';
import DataContext from '../../Components/Elements/eventContext';
import Skeleton from "react-loading-skeleton";
function AccountSidebar({ rowUserData }) {
  const location = useLocation();
  const navigate = useNavigate();
  const didMountRef = useRef(true);
  const contextValues = useContext(DataContext);
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  useEffect(() => {
    if (didMountRef.current) {
      getuserData()
    }
    didMountRef.current = false;
  }, []);
  const getuserData = () => {
    ApiService.fetchData("get-user-data").then((res) => {
      if (res.status == "success") {
        contextValues.setRowUserData(res.rowUserData);
        setTimeout(() => {
          setSpinnerLoading(false)
        }, 500);
      } else {
        setSpinnerLoading(false)
        localStorage.removeItem("USER_TOKEN");
        navigate("/");
      }
    });
  };
  const logoutProcess = () => {
    const confirmed = window.confirm("Are you sure you want to log out");
    if (confirmed) {
      ApiService.fetchData("logout").then((res) => {
        if (res.status === "success") {
          localStorage.removeItem("USER_TOKEN")
          navigate('/')
        }
      })
    }
  }
  return (
    <div className="col-lg-3">
      <div className="account-sidebar">
        <div className="account-user mb-3">
          <div className="au-imag"><img src="/images/account/user.png" /></div>
          <a href="/account/profile">
            <div className="au-content">
              <h6 className="mb-0">{spinnerLoading ? <Skeleton width={150} />: 'Hi! '+ contextValues.rowUserData.user_fname}</h6>
              <p className="mb-0">{spinnerLoading ? <Skeleton width={200} />: contextValues.rowUserData.user_email}</p>
            </div>
          </a>
        </div>
        <div className="aclist mb-3">
          <ul>
            <li className={location.pathname === "/account/profile" ? "active" : ""}>
              <a href="/account/profile">
                Profile<i className="ri-arrow-right-s-line"></i>
              </a>
            </li>
            <li className={location.pathname === "/account/address" ? "active" : ""}>
              <a href="/account/address">
                Address<i className="ri-arrow-right-s-line"></i>
              </a>
            </li>
            <li className={location.pathname === "/account/orders" ? "active" : ""}>
              <a href="/account/orders">
                My Orders<i className="ri-arrow-right-s-line"></i>
              </a>
            </li>
            {/* <li className={location.pathname === "/account/wallet" ? "active" : ""}>
              <a href="/account/wallet">
                Wallet<i className="ri-arrow-right-s-line"></i>
              </a>
            </li> */}
            <li className={location.pathname === "/account/help-and-support" ? "active" : ""}>
              <a href="/account/help-and-support">
                Help & Support<i className="ri-arrow-right-s-line"></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="aclist">
          <ul>
            <li>
              <a href="javascript:;" className="pb-0 pt-0" onClick={logoutProcess}>
                Logout<i className="ri-arrow-right-s-line"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default AccountSidebar