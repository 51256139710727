import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { ApiService } from "../../Components/Services/apiservices";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import sessionCartData from "../../Components/Elements/cart_session_data";
import ProductBox from "../../Components/Elements/product_box";
import MobileHeader from "../../Components/Elements/mobile_header";
import Productlistsidebar from "../../Components/Elements/product_list_sidebar";

function Collection() {
    const didMountRef = useRef(true);
    const dataArray = sessionCartData();
    const parsedCartSession = dataArray[1];
    const [resProductsData, setProductsData] = useState([]);
    const [cartCount, setCartCount] = useState(parsedCartSession.length);
    const [AttributeData, setAttributeData] = useState([]);
    const [categoriesData, setCategoriesData] = useState({});
    const [categoriesImageUrl, setCategoriesImageUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const [sorting, setSorting] = useState("");
    const [CatName, setCatName] = useState("");
    const [catImage, setCatImage] = useState("");
    const { type, slug } = useParams();
    const [setSession, SetSession] = useState("");
    const [scroll, setScroll] = useState();
    const [aryCategoryList, setCategoryList] = useState([]);
    const [resAttributesList, setAttributesList] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    useEffect(() => {
        setLoading(true);
        if (didMountRef.current) {
            getProductList()
            getProductListSidebar()
            SetSession(localStorage.getItem("USER_TOKEN"));
        }
        didMountRef.current = false;
    }, []);
    const handlecatImage = (categoryData) => {
        setCatImage(categoryData);
    };
    const getProductList = () => {
        let pageNumber = Math.ceil(resProductsData.length / 12) + 1;
        const dataString = {
            type: type,
            slug: slug,
        };
        ApiService.fetchData(
            "product-list" +
            "?page=" +
            pageNumber +
            "&_limit=12&slug=" +
            slug +
            "&type=" + type
        ).then((res) => {
            if (res.status === "success") {
                if (res.tagsData) {
                    setCatName(res.tagsData.tag_name);
                }
                if (res.categoriesData) {
                    setCatName(res.categoriesData.cat_name);
                }
                if (res.resProductsData.data.length > 0) {
                    const apires = res.resProductsData.data;
                    const mergeData = [...resProductsData, ...apires];
                    setProductsData(mergeData);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        });
    };

    const handleSortingClick = (sortingValue) => {
        setSorting(sortingValue);
        getfilterproducts(sortingValue)
    };
    const getProductListSidebar = () => {
        ApiService.fetchData("get-product-list-sidebar").then((res) => {
            if (res.status === "success") {
                setAttributesList(res.resAttributesList);
                setCategoryList(res.aryCategoryList);
                setLoading(false);
            } else {
                setLoading(false);
            }
        });
    };
    const handlecatName = (data) => {
        if (data) {
            setCatName(data);

        } else {

        }

    };

    const getfilterproducts = (sortingValue) => {
        const dataString = {
            sort: sortingValue ? sortingValue : "",
            type: type,
            slug: slug,
        };

        ApiService.postData("getFilterSortBy" + "?page=" + 1, dataString).then((res) => {
            if (res.status === "success") {
                if (sortingValue != null) {
                    setProductsData(res.data.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            } else {
            }
        });
    };

    const handleCategorySelect = (categoryId) => {
        let updatedSelectedCategories;
        if (selectedCategories.includes(categoryId)) {
            updatedSelectedCategories = selectedCategories.filter(id => id !== categoryId);
        } else {
            updatedSelectedCategories = [...selectedCategories, categoryId];
        }
        setSelectedCategories(updatedSelectedCategories);
        console.log("Selected categories:", updatedSelectedCategories);
        const dataString = {
            sort: sorting ? sorting : "",
            type: type,
            slug: slug,
            category: updatedSelectedCategories
        };

        ApiService.postData("getFilterCategory" + "?page=" + 1, dataString).then((res) => {
            if (res.status === "success") {
                setProductsData(res.data.data);
                setLoading(false);
            } else {
            }
        });

    };
    return (
        <>
            <BrowserView>
                <Header />
                <section className="section-gap-small">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 siderbar-filter">
                                <aside>
                                    <div className="filterbox mb-3">
                                        <div className="filterbox-title">Category</div>
                                        <div className="filterbox-body filter-common">
                                            <div className="filter-list">
                                                <ul>
                                                    {loading === true ? (
                                                        <>
                                                            {[...Array(8)].map((_, index) => (
                                                                <li key={index}>
                                                                    <Skeleton variant="text" width={200} />
                                                                </li>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {aryCategoryList.map((value) => (
                                                                <li key={value.cat_id}>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={selectedCategories.includes(value.cat_id)}
                                                                        onChange={() => handleCategorySelect(value.cat_id)}
                                                                    />
                                                                    <span>{value.cat_name}</span>
                                                                    {/* {value.children && value.children.length > 0 && (
                                                                        <ul>
                                                                            {value.children.map((child) => (
                                                                                <li key={child.cat_id}>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={selectedCategories.includes(child.cat_id)}
                                                                                        onChange={() => handleCategorySelect(child.cat_id)}
                                                                                    />
                                                                                    <span>{child.cat_name}</span>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    )} */}
                                                                </li>
                                                            ))}

                                                        </>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    {/* {resAttributesList.length > 0 &&
                                        resAttributesList.map((value) => (
                                            <div className="filterbox mb-3">
                                                <div className="filterbox-title">{value.attribute_name}</div>
                                                <div className="filterbox-body filter-common">
                                                    <div className="filter-list">
                                                        <ul>
                                                            {value.attributeterms &&
                                                                value.attributeterms.length > 0 &&
                                                                value.attributeterms.map((subvalue) => (
                                                                    <li key={subvalue.terms_id}>
                                                                        <input type="checkbox" className="attributecheckbox" />
                                                                        <span>{subvalue.terms_name}</span>
                                                                    </li>
                                                                ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        ))} */}
                                </aside>
                            </div>

                            <div className="col-lg-9">
                                <nav aria-label="breadcrumb" className="innerbreadcumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            {(() => {
                                                if (type === undefined && slug === undefined) {
                                                    return ('All Collection');
                                                } else if (CatName && CatName) {
                                                    return (CatName);
                                                } else {
                                                    return (<Skeleton width={100} height={10} />);
                                                }
                                            })()}
                                        </li>
                                    </ol>
                                </nav>
                                <div className="page-title">
                                    <h2 className="mb-0">
                                        {(() => {
                                            if (type === undefined && slug === undefined) {
                                                return ('All Collection');
                                            } else if (CatName && CatName) {
                                                return (CatName && CatName);
                                            } else {
                                                return (<Skeleton width={200} height={30} />);
                                            }
                                        })()}
                                    </h2>
                                </div>
                                <div className="shortby">
                                    <div className="productcount">
                                        Showing results of {resProductsData.length} products.
                                    </div>
                                    <div className="shortbydrop">
                                        <div className="shortbydrop-inner select">
                                            <label>Sort by</label>
                                            <select
                                                onChange={(e) => handleSortingClick(e.target.value)}
                                            >
                                                <option value="">Select</option>
                                                <option value="alphaasc">Alphabetically, A-Z</option>
                                                <option value="alphadesc">Alphabetically, Z-A</option>
                                                <option
                                                    value="asc"
                                                    selected={sorting === "asc" ? true : false}
                                                >
                                                    Price, low to high
                                                </option>
                                                <option
                                                    value="desc"
                                                    selected={sorting === "desc" ? true : false}
                                                >
                                                    Price, high to low
                                                </option>
                                                <option value="dateasc">Date, old to new</option>
                                                <option value="datedesc">Date, new to old</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <InfiniteScroll
                                    dataLength={resProductsData.length}
                                    next={scroll === true ? getProductList : ""}
                                    hasMore={true}
                                    endMessage={<p>No more data to load.</p>}
                                    style={{ overflow: "hidden !important" }}
                                >
                                    {loading === true ? (
                                        <div className="row g-3 cols-xl-4">
                                            {[...Array(9)].map((_, index) => (
                                                <div className="product" key={index}>
                                                    <figure className="product-media">
                                                        <Skeleton
                                                            variant="text"
                                                            width={'100%'}
                                                            height={315}
                                                        />
                                                    </figure>
                                                    <div className="product-details">
                                                        <h3 className="product-name">
                                                            <Skeleton variant="text" width={150} />
                                                        </h3>
                                                        <div className="product-price">
                                                            <Skeleton variant="text" width={150} />
                                                        </div>
                                                    </div>
                                                </div>

                                            ))}
                                        </div>
                                    ) : (
                                        <>
                                            {resProductsData.length > 0 ? (
                                                <div className="row g-3 cols-xl-4">
                                                    {resProductsData.map((subvalue, indexProduct) => {

                                                        return (
                                                            <ProductBox productValue={subvalue} indexProduct={indexProduct} />

                                                        );
                                                    })}
                                                </div>
                                            ) : (
                                                <h2>No products found.</h2>
                                            )}
                                        </>
                                    )}
                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </BrowserView>
            <MobileView>
                <MobileHeader
                    PageName={
                        (() => {
                            if (type === undefined && slug === undefined) {
                                return 'All Collection';
                            } else if (CatName && CatName) {
                                return CatName;
                            } else {
                                return <Skeleton width={100} height={10} />;
                            }
                        })()
                    }
                    Route=""
                    cartCount={cartCount}
                />


                <div id="appCapsule" className="mt-15">
                    <div className="container">
                        <InfiniteScroll
                            dataLength={resProductsData.length}
                            next={scroll === true ? getProductList : ""}
                            hasMore={true}
                            endMessage={<p>No more data to load.</p>}
                            style={{ overflow: "hidden !important" }}
                        >
                            {loading === true ? (
                                <div className="row g-3 cols-xl-4">
                                    {[...Array(9)].map((_, index) => (
                                        <div className="col-6" key={index}>
                                            <div className="product" key={index}>
                                                <figure className="product-media">
                                                    <Skeleton
                                                        variant="text"
                                                        width={"100%"}
                                                        height={170}
                                                    />
                                                </figure>
                                                <div className="product-details">
                                                    <div className="product-details-inner">
                                                        <h3 className="product-name">
                                                            <Skeleton
                                                                variant="text"
                                                                width={"100%"}
                                                                height={10}
                                                            />
                                                        </h3>
                                                        <div className="product-price">
                                                            <Skeleton variant="text" width={100} height={5} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <>
                                    {resProductsData.length > 0 ? (
                                        <div className="row g-3 cols-2">
                                            {resProductsData.map((subvalue, indexProduct) => {
                                                return (
                                                    <ProductBox productValue={subvalue} indexProduct={indexProduct} />
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <h2>Products Coming Soon.</h2>
                                    )}
                                </>
                            )}
                        </InfiniteScroll>
                    </div>
                </div>

                <Productlistsidebar
                    slug={slug}
                    type={type}
                    categoryName={handlecatName}
                    categoryImage={handlecatImage}
                    setscroll=""
                />

            </MobileView>

        </>
    );
}
export default Collection;
