import React, { useEffect, useState, useRef } from "react";
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { BrowserView, MobileView } from 'react-device-detect'
import MobHeader from '../../Components/Header/mobheader'
import AccountSidebar from './account_sidebar'
import { ApiService } from "../../Components/Services/apiservices";
import { useNavigate } from "react-router-dom";
import numeral from "numeral";
import { useParams } from "react-router-dom";
import moment from "moment";
import DataContext from "../../Components/Elements/eventContext";
import Skeleton from "react-loading-skeleton";
import Loader from "react-js-loader";
import multiCurrency from "../../Components/Elements/multi_currrency";
import OrderCancelModal from "../../Components/Modals/order_cancel_modal";
import orderStatusComponent from "../../Components/Elements/order_status";
function OrderDetail() {
  const didMountRef = useRef(true);
  const [orderData, setOrderData] = useState({});
  const [itemsData, setItemsData] = useState([]);
  const [transId, setTransId] = useState(0);
  const [tdId, setTdId] = useState(0);
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const [spinnerLoadingSec, setSpinnerLoadingSec] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showReturnModal, setShowReturnModal] = useState(false);
  useEffect(() => {
    if (didMountRef.current) {
      getOrderData();
    }
    didMountRef.current = false;
  }, []);

  const getOrderData = () => {
    const getOrderDetail = {
      trans_id: id,
    };
    ApiService.postData("get-order-detail", getOrderDetail).then((res) => {
      if (res.status === "success") {
        setOrderData(res.row_orders_data);
        setItemsData(res.row_orders_data.items);
        setTimeout(() => {
          setSpinnerLoading(false)
          setTimeout(() => {
            setSpinnerLoadingSec(false)
          }, 1000);
        }, 500);
      } else {
        setTimeout(() => {
          setSpinnerLoading(false)
          setTimeout(() => {
            setSpinnerLoadingSec(false)
          }, 1000);
        }, 500);
      }
    });
  };


  const handleOpenModal = (status, transid, tdid) => {
    setTransId(transid);
    setTdId(tdid);
    setShowCancelModal(status);
  };
  const handleCancelModal = () => {
    setShowCancelModal(false);
  };

  return (
    <>
      <BrowserView>
        <Header />
        <div className="subheader">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item"><a href="/account/orders">My Orders</a></li>
                    <li className="breadcrumb-item active" aria-current="page">My Order Details</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <section className="section-gap-medium">
          <div className="container">
            <div className="row">
              <AccountSidebar />
              <div className="col-lg-9">
                <div className="acpanel">
                  <div className="acpanel-header">
                    <h4>
                      {spinnerLoading
                        ? <Skeleton width={300} />
                        : `Order Details (${orderData.trans_order_number})`
                      }
                    </h4>
                  </div>
                  <div className="acpanel-body">
                    <div className="order-box">
                      <div className="info">
                        <div className="info-delivery">
                          <h6 className="mb-1"> {spinnerLoading
                            ? <Skeleton width={100} />
                            : `Delivery Address`
                          }</h6>
                          <p className="mb-0 mt-0">
                            <strong>
                              {spinnerLoading
                                ? <Skeleton width={150} />
                                : orderData.trans_user_name
                              }
                            </strong>
                          </p>
                          <p className="tx-12">
                            {spinnerLoading
                              ? <Skeleton width={300} />
                              : orderData.trans_delivery_address
                            }
                          </p>
                          <p className="mb-0 mt-0">
                            {spinnerLoading
                              ? <Skeleton width={200} />
                              : <>
                                <strong>Email Id: </strong>{orderData.trans_user_email}
                              </>
                            }
                          </p>
                          <p className="mb-0 mt-0">
                            {spinnerLoading
                              ? <Skeleton width={200} />
                              : <><strong>Mobile number : </strong> {orderData.trans_user_mobile}</>
                            }
                          </p>
                          <p className="mb-0 mt-0">
                            {spinnerLoading
                              ? <Skeleton width={150} />
                              : <><strong>Txn Id : </strong> {orderData.trans_order_number}</>
                            }
                          </p>
                        </div>
                        {itemsData.map((value, index) => {
                          return (
                        <div className="order-box-items-content">
                                {(() => {
                                  return orderStatusComponent(value)
                                })()}
                              </div>
                                 );
                                })}
                      </div>
                      <div className="bcode">
                        <div className="orderid-box mb-2">
                          <h6 className="mb-0">
                            {spinnerLoading
                              ? <Skeleton width={100} />
                              : 'ORDER ID'
                            }
                          </h6>
                          <p className="mb-0 tx-13">
                            {spinnerLoading
                              ? <Skeleton width={100} />
                              : orderData.trans_order_number
                            }
                          </p>
                        </div>
                        <p className="tx-color-03 mb-0 tx-13">
                          {spinnerLoading
                            ? <Skeleton width={80} />
                            : 'ORDER ON'
                          }
                        </p>
                        <p className="tx-12">
                          {spinnerLoading
                            ? <Skeleton width={120} />
                            : moment(orderData.trans_datetime).format("ddd, DD MMM YYYY")
                          }
                          <br />
                          {spinnerLoading
                            ? <Skeleton width={80} />
                            : moment(orderData.trans_datetime, "HH:mm").format("hh:mm A")
                          }
                        </p>
                        <div className="order-box-items-center">
                                 <a
                                    href="javascript:void(0)"
                                    className="btn btn-outline-primary btn-md"
                                    onClick={(e) =>
                                      handleOpenModal(true, orderData.td_trans_id, orderData.td_id)
                                    }
                                  >
                                    Cancel Order
                                  </a>
                            </div>

                      </div>
                      
                    </div>
                    {!spinnerLoadingSec
                      ? <>
                      
                        {/* {itemsData.map((value, index) => {
                          return (
                            <div className="order-box-items mb-3" key={index}>
                              <div className="order-box-items-img">
                                <img src={value.td_item_image}></img>
                              </div> 
                              <div className="order-box-items-center">
                               <h6 className="tx-14 mb-1">{value.td_item_title}</h6>
                                <p>Variation: {value.td_item_unit}</p> 
                               {value.td_item_status === 1 &&
                                  value.td_accept_status === 1 ? (
                                  <a
                                    href="javascript:void(0)"
                                    className="btn btn-primary-outline btn-small"
                                    onClick={(e) =>
                                      handleOpenModal(true, value.td_trans_id, value.td_id)
                                    }
                                  >
                                    Cancel Order
                                  </a>
                                ) : null} 
                                 {value.td_item_status === 4 && returnDate(value.td_delivered_date) ? (
                                    <a
                                      href="javascript:void(0)"
                                      className="btn btn-primary-outline btn-small"
                                      onClick={(e) =>
                                        handleReturnModal(true, value.td_trans_id, value.td_id)
                                      }
                                    >
                                      Return Order
                                    </a>
                                  ) : null} 
                              </div>
                               <div className="order-box-items-content">
                                {(() => {
                                  return orderStatusComponent(value)
                                })()}
                              </div>
                            </div>
                          );
                        })} */}
                        <div className="card-table">
                          <div className="card-table-section">
                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th>Image</th>
                                  <th>Items</th>
                                  <th >Variation</th>

                                  <th className="text-center">QTY</th>
                                  <th className="text-center">Price</th>
                                  <th className="text-center">Amount</th>
                                </tr>
                              </thead>

                              <tbody>
                                {itemsData.map((value, index) => {
                                  return (
                                    <tr key={index}>
                                           <td>
                                           <img src={value.td_item_image} style={{width:"50px",height:'50px',objectFit:"cover"}}></img>
                                      </td>
                                      <td>
                                        {value.td_item_title}
                                        <br />
                                      </td>
                                      <td className="text-center">
                                      {value.td_item_unit ? value.td_item_unit : "-" }
                                      </td>
                                      <td className="text-center">
                                        {value.td_item_qty}
                                      </td>
                                      <td className="text-center">

                                        {multiCurrency(
                                          value.td_item_net_price
                                        )}
                                      </td>
                                      <td className="text-center">

                                        {multiCurrency(
                                          value.td_item_net_price *
                                          value.td_item_qty
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                                <tr>
                                  <td colSpan="1"></td>
                                  <td colSpan=""></td>
                                  <td colSpan=""></td>
                                  <td colSpan=""></td>

                                  <td>Sub Total</td>
                                  <td className="text-center">
                                    {multiCurrency(orderData.item_sub_total)}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="2"></td>
                                  <td colSpan=""></td>
                                  <td colSpan=""></td>
                                  <td>Discount</td>
                                  <td className="text-center">
                                    -
                                    {multiCurrency(
                                      orderData.trans_discount_amount
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="2"></td>
                                  <td colSpan=""></td>
                                  <td colSpan=""></td>

                                  <td>Coupon Discount</td>
                                  <td className="text-center">

                                    -{multiCurrency(
                                      orderData.trans_coupon_dis_amt
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="2"></td>
                                  <td colSpan=""></td>
                                  <td colSpan=""></td>

                                  <td>Delivery Charges</td>

                                  <td className="text-center">

                                    {multiCurrency(
                                      orderData.trans_shipping_amount
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="2"></td>
                                  <td colSpan=""></td>
                                  <td colSpan=""></td>

                                  <td>
                                    <strong>Grand total</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>

                                      {orderData.trans_amt}
                                    </strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                      : <Loader type="spinner-cub" bgColor={'#1b8057'} color={'#1b8057'} size={50} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </BrowserView>
      <MobileView>
        <MobHeader PageName="Order Detail" Route="account/orders" />
        <section className="section-gap-medium">
          <div className="container">
            <div className="row">
              <div className="col-lg-9">
                <div className="acpanel">
                  <div className="acpanel-header">
                    <h4>
                      {spinnerLoading
                        ? <Skeleton width={300} />
                        : `Order Details (${orderData.trans_order_number})`
                      }
                    </h4>
                  </div>
                  <div className="acpanel-body">
                    <div className="order-box">
                      <div className="info">
                        <div className="info-delivery">
                          <h6 className="mb-1"> {spinnerLoading
                            ? <Skeleton width={100} />
                            : `Delivery Address`
                          }</h6>
                          <p className="mb-0 mt-0">
                            <strong>
                              {spinnerLoading
                                ? <Skeleton width={150} />
                                : orderData.trans_user_name
                              }
                            </strong>
                          </p>
                          <p className="tx-12">
                            {spinnerLoading
                              ? <Skeleton width={300} />
                              : orderData.trans_delivery_address
                            }
                          </p>
                          <p className="mb-0 mt-0">
                            {spinnerLoading
                              ? <Skeleton width={200} />
                              : <>
                                <strong>Email Id: </strong>{orderData.trans_user_email}
                              </>
                            }
                          </p>
                          <p className="mb-0 mt-0">
                            {spinnerLoading
                              ? <Skeleton width={200} />
                              : <><strong>Mobile number : </strong> {orderData.trans_user_mobile}</>
                            }
                          </p>
                          <p className="mb-0 mt-0">
                            {spinnerLoading
                              ? <Skeleton width={150} />
                              : <><strong>Txn Id : </strong> {orderData.trans_order_number}</>
                            }
                          </p>
                        </div>
                      </div>
                      <div className="bcode">
                        <div className="orderid-box mb-2">
                          <h6 className="mb-0">
                            {spinnerLoading
                              ? <Skeleton width={100} />
                              : 'ORDER ID'
                            }
                          </h6>
                          <p className="mb-0 tx-13">
                            {spinnerLoading
                              ? <Skeleton width={100} />
                              : orderData.trans_order_number
                            }
                          </p>
                        </div>
                        <p className="tx-color-03 mb-0 tx-13">
                          {spinnerLoading
                            ? <Skeleton width={80} />
                            : 'ORDER ON'
                          }
                        </p>
                        <p className="tx-12">
                          {spinnerLoading
                            ? <Skeleton width={120} />
                            : moment(orderData.trans_datetime).format("ddd, DD MMM YYYY")
                          }
                          <br />
                          {spinnerLoading
                            ? <Skeleton width={80} />
                            : moment(orderData.trans_datetime, "HH:mm").format("hh:mm A")
                          }
                        </p>
                      </div>
                    </div>
                    {!spinnerLoadingSec
                      ? <>
                        {itemsData.map((value, index) => {
                          return (
                            <div className="order-box-items mb-3" key={index}>
                              <div className="order-box-items-img">
                                <img src={value.td_item_image}></img>
                              </div>
                              <div className="order-box-items-center">
                                <h6 className="tx-14 mb-1">{value.td_item_title}</h6>
                                <p>Variation: {value.td_item_unit}</p>
                                {value.td_item_status === 1 &&
                                  value.td_accept_status === 1 ? (
                                  <a
                                    href="javascript:void(0)"
                                    className="btn btn-primary-outline btn-small"
                                    onClick={(e) =>
                                      handleOpenModal(true, value.td_trans_id, value.td_id)
                                    }
                                  >
                                    Cancel Order
                                  </a>
                                ) : null}
                                {/* {value.td_item_status === 4 && returnDate(value.td_delivered_date) ? (
                                    <a
                                      href="javascript:void(0)"
                                      className="btn btn-primary-outline btn-small"
                                      onClick={(e) =>
                                        handleReturnModal(true, value.td_trans_id, value.td_id)
                                      }
                                    >
                                      Return Order
                                    </a>
                                  ) : null} */}
                              </div>
                              {/* <div className="order-box-items-content">
                                {(() => {
                                  return orderStatusComponent(value)
                                })()}
                              </div> */}
                            </div>
                          );
                        })}
                        <div className="card-table">
                          <div className="card-table-section">
                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th>Items</th>
                                  <th className="text-center">QTY</th>
                                  <th className="text-center">Price</th>
                                  <th className="text-center">Amount</th>
                                </tr>
                              </thead>

                              <tbody>
                                {itemsData.map((value, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        {value.td_item_title}
                                        <br />
                                      </td>
                                      <td className="text-center">
                                        {value.td_item_qty}
                                      </td>
                                      <td className="text-center">

                                        {multiCurrency(
                                          value.td_item_net_price
                                        )}
                                      </td>
                                      <td className="text-center">

                                        {multiCurrency(
                                          value.td_item_net_price *
                                          value.td_item_qty
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                                <tr>
                                  <td colSpan="1"></td>
                                  <td colSpan=""></td>
                                  <td>Sub Total</td>
                                  <td className="text-center">
                                    {multiCurrency(orderData.item_sub_total)}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="2"></td>
                                  <td>Discount</td>
                                  <td className="text-center">
                                    -
                                    {multiCurrency(
                                      orderData.trans_discount_amount
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="2"></td>
                                  <td>Coupon Discount</td>
                                  <td className="text-center">

                                    -{multiCurrency(
                                      orderData.trans_coupon_dis_amt
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="2"></td>
                                  <td>Delivery Charges</td>

                                  <td className="text-center">

                                    {multiCurrency(
                                      orderData.trans_shipping_amount
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="2"></td>
                                  <td>
                                    <strong>Grand total</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>

                                      {orderData.trans_amt}
                                    </strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                      : <Loader type="spinner-cub" bgColor={'#1b8057'} color={'#1b8057'} size={50} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </MobileView>
      {showCancelModal && (
        <OrderCancelModal 
          transId={transId}
          tdId={tdId}
          showmodal={showCancelModal}
          onChildData={setShowCancelModal}
          closeModal={handleCancelModal}
        />
      )}
    </>
  )
}

export default OrderDetail