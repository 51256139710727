import React, { useEffect, useState, useRef,useContext } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import { BrowserView, MobileView } from "react-device-detect";
import DataContext from "../../Components/Elements/eventContext";
import Header from "../../Components/Header";
import AccountSidebar from "./account_sidebar";
import Footer from "../../Components/Footer";
import MobileHeader from "../../Components/Elements/mobile_header";
import MobHeader from "../../Components/Header/mobheader";
function Wallet() {
    const { settingData } = useContext(DataContext);
    const didMountRef = useRef(true);
    const [rowUserData, setRowUserData] = useState({});
    useEffect(() => {
      if (didMountRef.current) {
        getuserData();
      }
      didMountRef.current = false;
    }, []);
    const getuserData = () => {
      ApiService.fetchData("get-user-data").then((res) => {
        if (res.status == "success") {
          setRowUserData(res.rowUserData);
        } 
      });
    };
  return (
    <>
        <BrowserView>
    <Header state="inner-header" />
          <div className="subheader">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><a href="/">Home</a></li>
                      <li className="breadcrumb-item active" aria-current="page">Help & Support</li>
                    </ol>
                  </nav>

                </div>
              </div>
            </div>
          </div>

          <section className="section-gap-medium">
              <div className="container">
                <div className="row">
                  <AccountSidebar rowUserData={rowUserData} />
                  <div className="col-lg-9">
                    <div className="acpanel">
                      <div className="acpanel-header">
                        <h4>Wallet</h4>
                      </div>
               
                    </div>
                  </div>
                </div>
              </div>
            </section>

        <Footer />
    </BrowserView>

    <MobileView>
    <MobHeader  PageName="Wallet"  Route="account/account-overview"/>

    </MobileView>
    
    </>

  )
}

export default Wallet