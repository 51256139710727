import { useEffect, useRef, useState, useContext } from 'react';
import { ApiService } from '../Services/apiservices';
import DataContext from '../Elements/eventContext';
import Modal from 'react-bootstrap/Modal';
import StarRating from '../Elements/starrating';
import moment from "moment/moment";
import Loader from "react-js-loader";
function ReviewListModal() {
    const contextValues = useContext(DataContext);
    const [reviewData, setReviewData] = useState([]);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const didMountRef = useRef(true);
    useEffect(() => {
        //if (didMountRef.current) {
            console.log("sdsds",contextValues.rowProductsData.product_id);
            if(contextValues.rowProductsData.product_id > 0){
                getReviewData()
            }
        //}
        //didMountRef.current = false;
    }, [contextValues]);

    const getReviewData = async () => {
        const formData = new FormData();
        formData.append('product_id', contextValues.rowProductsData.product_id);
        setSpinnerLoading(true)
        ApiService.postData("reviewlist", formData)
            .then((res) => {
                if (res.status === "success") {
                    setReviewData(res.resProductReviewData)
                    setSpinnerLoading(false)
                } else {
                    setSpinnerLoading(false)
                }
            })
            .catch((error) => {
                setSpinnerLoading(false)
            });
    };

    const reviewListModal = () => {
        contextValues.setToggleReviewListModal(!contextValues.toggleReviewListModal)
    }
    return (
        <>
            <Modal show={contextValues.toggleReviewListModal} onHide={(e) => reviewListModal()} className='reviewModal right'>
                <div className="reviewModal-content">
                    <button type="button" className="btn-close" onClick={(e) => reviewListModal()}></button>
                    <div className="reviewModal-header">
                        <div>
                            <h4 className="reviewModal-title">Customer Reviews & Ratings</h4>
                            <p>View all reviews & ratings </p>
                        </div> 
                    </div>
                    {spinnerLoading ?
                        <Loader type="spinner-cub" bgColor={'#000000'} color={'#000000'} size={20} /> 
                        :  
                        <div className="comentlist">
                            <ul>
                              {reviewData.map((value, index) => (
                                <li key={index}>
                                  <div className="comment">
                                    <figure className="comment-media">
                                      <img
                                        src="/images/user.png"
                                        alt={value.pr_title}
                                      />
                                    </figure>
                                    <div className="comment-body">
                                      {value.pr_rating &&
                                        value.pr_rating > 0 ? (
                                        <div className="ratings-container mb-2">
                                          <StarRating
                                            numberOfStars={value.pr_rating}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="comment-rating"></div>
                                      <div className="comment-user">
                                        <span className="comment-meta">by <span className="comment-name">{value.pr_title}</span>on <span className="comment-date">{moment(value.pr_created).format("MMM D, YYYY")}</span></span>
                                      </div>
                                      <div className="comment-content">
                                        <p>{value.pr_review}</p>
                                      </div>
                                      {value.pr_image1 && value.pr_image1.split(',').length > 0 && (
                                        <div className="comment-images">
                                          {value.pr_image1.split(',').map((imageUrl, index) => (
                                            <img key={index} src={imageUrl} alt={`Image ${index + 1}`} width={50} />
                                          ))}
                                        </div>
                                      )}

                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div> }
                </div>
            </Modal>
        </>
    );
}

export default ReviewListModal;
