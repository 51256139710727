import React, { useEffect, useState, useRef,useContext } from "react";
import { validEmail, validNumber } from "../../Components/Elements/Regex";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { ApiService } from "../../Components/Services/apiservices";
import { BrowserView, MobileView } from "react-device-detect";
import AccountSidebar from "./account_sidebar";
import { useNavigate } from "react-router-dom";
import MobHeader from "../../Components/Header/mobheader";
import DataContext from "../../Components/Elements/eventContext";
function Profile() {
  const didMountRef = useRef(true);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); 
  const contextValues = useContext(DataContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (didMountRef.current) {
    }
    didMountRef.current = false;
  }, []);

  const onTodoChange = (e) => {
    const { name, value } = e.target;
    contextValues.setRowUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdateProfile = () => {
    let counter = 0;
    const myElements = document.getElementsByClassName("required");
    for (let i = 0; i < myElements.length; i++) {
      if (myElements[i].value === "") {
        myElements[i].style.border = "1px solid red";
        counter++;
      } else {
        myElements[i].style.border = "";
      }
    }
    if (counter === 0) {
      setErrorMessage("");
      if (contextValues.rowUserData.user_fname === "") {
        setErrorMessage("Please enter Full Name");
        return false;
      } if (contextValues.rowUserData.user_email === "") {
        setErrorMessage("Please enter Email Id");
        return false;
      } if (!validEmail.test(contextValues.rowUserData.user_email)) {
        setErrorMessage("Please enter valid Email Id");
        return false;
      } if (contextValues.rowUserData.user_mobile === "") {
        setErrorMessage("Please enter Mobile Number");
        return false;
      } if (!validNumber.test(contextValues.rowUserData.user_mobile)) {
        setErrorMessage("Please enter valid Mobile Number");
        return false;
      }
      setSpinnerLoading(true);
      ApiService.postData("update-profile", contextValues.rowUserData).then((res) => {
        if (res.status === "success") {
          setSuccessMessage(res.message);
          setTimeout(() => {
            setSuccessMessage('');
            setSpinnerLoading(false);
          }, 1000);
        } else {
          setSuccessMessage(res.message);
          setTimeout(() => {
            setSuccessMessage('');
            setSpinnerLoading(false);
          }, 1000);
        }
      });
    }
  };

  return (
    <>
      <BrowserView>
        <Header state="inner-header" />
        <div className="subheader">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Profile</li>
                  </ol>
                </nav>

              </div>
            </div>
          </div>
        </div>


        <section className="section-gap-medium">
          <div className="container">
            <div className="row">
              <AccountSidebar/>
              <div className="col-lg-9">
                {errorMessage && (
                  <div className="alert alert-danger" role="alert">
                    {errorMessage}
                  </div>
                )}
                {successMessage && (
                  <div className="alert alert-success" role="alert">
                    {successMessage}
                  </div>
                )}
                <div className="acpanel">
                  <div className="acpanel-header">
                    <h4>My Profile</h4>
                  </div>
                  <div className="acpanel-body">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group mb-3">
                          <label htmlFor="user_fname">Full Name</label>
                          <input
                            type="text"
                            name="user_fname"
                            className="form-control required"
                            value={contextValues.rowUserData.user_fname}
                            placeholder="Full Name"
                            onChange={(e) => onTodoChange(e)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label
                            htmlFor="user_email"
                            className="form-label"
                          >
                            Email Address
                          </label>
                          <input
                            type="text"
                            name="user_email"
                            className="form-control required"
                            value={contextValues.rowUserData.user_email}
                            placeholder="Email Address"
                            onChange={(e) => onTodoChange(e)}
                            readOnly={true}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label
                            htmlFor="user_mobile"
                            className="form-label"
                          >
                            Mobile Number
                          </label>
                          <input
                            type="number"
                            name="user_mobile"
                            className="form-control required"
                            value={contextValues.rowUserData.user_mobile}
                            placeholder="Mobile Number"
                            onChange={(e) => onTodoChange(e)}
                            readOnly={true}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2">
                      <button type="button" className="btn btn-primary btn-full btn-md ckbutton  mt-2" onClick={(e) => handleUpdateProfile()} disabled={spinnerLoading} >{spinnerLoading ? <img src="/images/loder01.gif" width="60px" height="11px" /> : 'Update'}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </BrowserView>
      <MobileView>
        <MobHeader PageName="My Profile" Route="account/account-overview" />
        <section className="section-gap-medium">
          <div className="container">
            <div className="row">
              <div className="col-lg-9">
                {errorMessage && (
                  <div className="alert alert-danger" role="alert">
                    {errorMessage}
                  </div>
                )}
                {successMessage && (
                  <div className="alert alert-success" role="alert">
                    {successMessage}
                  </div>
                )}
                <div className="acpanel">
                  <div className="acpanel-body">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group mb-3">
                          <label htmlFor="user_fname">Full Name</label>
                          <input
                            type="text"
                            name="user_fname"
                            className="form-control required"
                            value={contextValues.rowUserData.user_fname}
                            placeholder="Full Name"
                            onChange={(e) => onTodoChange(e)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label
                            htmlFor="user_email"
                            className="form-label"
                          >
                            Email Address
                          </label>
                          <input
                            type="text"
                            name="user_email"
                            className="form-control required"
                            value={contextValues.rowUserData.user_email}
                            placeholder="Email Address"
                            onChange={(e) => onTodoChange(e)}
                            readOnly={true}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label
                            htmlFor="user_mobile"
                            className="form-label"
                          >
                            Mobile Number
                          </label>
                          <input
                            type="number"
                            name="user_mobile"
                            className="form-control required"
                            value={contextValues.rowUserData.user_mobile}
                            placeholder="Mobile Number"
                            onChange={(e) => onTodoChange(e)}
                            readOnly={true}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <button type="button" className="btn btn-primary btn-full btn-md ckbutton  mt-2" onClick={(e) => handleUpdateProfile()} disabled={spinnerLoading} >{spinnerLoading ? <img src="/images/loder01.gif" width="60px" height="11px" /> : 'Update'}</button>
                      </div> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </MobileView>


    </>
  );
}
export default Profile;
