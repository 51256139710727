import React, { useEffect, useRef, useState, useContext } from 'react';
import DataContext from '../Elements/eventContext'
import Loader from "react-js-loader";
import { ApiService } from '../Services/apiservices';
import sessionCartData from '../Elements/cart_session_data';
import multiCurrency from '../Elements/multi_currrency';
import useRazorpay from "react-razorpay";
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
function PaymentModal() {
    const didMountRef = useRef(true);
    const navigate = useNavigate();
    const dataArray = sessionCartData();
    const contextValues = useContext(DataContext);
    const [proccessLoader, setProccessLoader] = useState(false);
    const [Razorpay] = useRazorpay();
    useEffect(() => {
        if (didMountRef.current) {
        }
        didMountRef.current = false;
    }, []);
    const paymentModal = () => {
        contextValues.setTogglePaymentModal(!contextValues.togglePaymentModal)
    }
    const selectPaymentMethod = (type) => {
        if (type === 'COD') {
            cashOnDelivery()
        } else {
            createTempOrder(type)

        }

    }
    const cashOnDelivery = () => {
        const dataString = {
            cartSummary: contextValues.cartSummary,
            parsedAddressSession: contextValues.addressSession,
            parsedCartSession: contextValues.cartSessionData,
            parsedCouponSession: contextValues.couponSession,
            paymentMethod: 'COD',
            shippingCharge: contextValues.shippingAmount,
        };
        setProccessLoader(true)
        ApiService.postData("makecodorder", dataString).then((res) => {
            if (res.status === "success") {
                localStorage.removeItem("CART_SESSION")
                localStorage.removeItem("ADDRESS_SESSION")
                localStorage.removeItem("COUPON_SESSION")
                navigate('/thankyou/' + res.order_number)
                window.location.reload()
            } else {
                setProccessLoader(false)
            }
        });
    }

    const createTempOrder = (type) => {
        const dataString = {
            cartSummary: contextValues.cartSummary,
            parsedAddressSession: contextValues.addressSession,
            parsedCartSession: contextValues.cartSessionData,
            parsedCouponSession: contextValues.couponSession,
            paymentMethod: type,
            shippingCharge: contextValues.shippingAmount,
        };
        setProccessLoader(true)
        ApiService.postData("createtemporder", dataString).then((res) => {
            if (res.status === "success") {
                setProccessLoader(false)
                handlePayment(type, res)
            } else {
                setProccessLoader(false);
            }
        });
    };

    const handlePayment = async (type, res) => {
        const options = {
            key: "rzp_live_ndkiK2I7HW2jvQ", // Enter the Key ID generated from the Dashboard
            amount: res.row_temp_trans.total_amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: "INR",
            name: "DryFu",
            description: "DryFu",
            image: contextValues.settingImageBaseUrl + contextValues.settingData.logo,
            order_id: res.row_temp_trans.temp_razorpay_order_id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
            handler: function (response) {
                RazorPayProcess(response, res.row_temp_trans.temp_trans_order_id, type);
            },
            prefill: {
                name: contextValues.addressSession.ua_name,
                email: contextValues.addressSession.ua_email,
                contact: contextValues.addressSession.ua_mobile,
            },
            notes: {
                address: "DryFu",
            },
            theme: {
                color: "#1b8057",
            },
            method: {
                netbanking: type == 'Net Banking' ? true : false,
                card: type == 'Debit/Credit Cards' ? true : false,
                upi: type == 'UPI' ? true : false,
                wallet: type == 'Wallet' ? true : false,
            },
        };

        const rzp1 = new Razorpay(options);

        rzp1.on("payment.failed", function (response) {
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
        });

        rzp1.open();
    };

    const RazorPayProcess = (response, orderId, type) => {
        const paymentData = {
            orderId: orderId,
            paymentId: response.razorpay_payment_id,
            cartSummary: contextValues.cartSummary,
            parsedAddressSession: contextValues.addressSession,
            parsedCartSession: contextValues.cartSessionData,
            parsedCouponSession: contextValues.couponSession,
            paymentMethod: type,
            shippingCharge: contextValues.shippingAmount,
        };
        setProccessLoader(true)
        ApiService.postData("paymentprocessing", paymentData).then((result) => {
            if (result.status === "success") {
                localStorage.removeItem("CART_SESSION")
                localStorage.removeItem("ADDRESS_SESSION")
                localStorage.removeItem("COUPON_SESSION")
                setProccessLoader(false);
                navigate('/thankyou/' + result.order_number)
                window.location.reload();
            } else {
                setProccessLoader(false);
            }
        });
    };
    return (
        <>
            <Modal show={contextValues.togglePaymentModal} onHide={(e) => paymentModal()}>
                <div className='couponsec'>
                    <div className='couponsecHeader'>
                        <h5 className='mb-0'>Payment</h5>
                        <button type='button' className='btn btn-transparent' onClick={(e) => paymentModal()}> <i className="ri-close-line ri-xl"></i></button>
                    </div>
                    <div className='couponsec-body'>
                        {proccessLoader && <Loader type="spinner-cub" bgColor={'#1b8057'} color={'#1b8057'} size={50} />}
                        <div className='ckboxbo mb-15'>
                            <div className='ckboxbo-inner'>
                                <div className='ckboxbo-icon'>
                                    <i className="ri-truck-line ri-xl mr-10"></i>
                                    <span>Delivery</span>
                                </div>
                                <div className='ckboxbo-itemscount' onClick={(e) => paymentModal()}> <i className="ri-edit-box-line ri-lg ml-5"></i></div>
                            </div>
                            <div className='addressselbox tx-gray'>
                                <div className='selectaddress mt-10'>
                                    <p className='mb-0 tx-13'>{contextValues.addressSession.ua_house_no}, {contextValues.addressSession.ua_city_name}, {contextValues.addressSession.ua_state_name}, {contextValues.addressSession.ua_pincode}</p>
                                </div>
                            </div>
                        </div>
                        {/* Price Details */}
                        <div className='ckboxbo mb-15'>
                            <div className='ckboxbo-inner' data-bs-toggle="collapse" href="#collapsePrice" role="button" aria-expanded="true" aria-controls="collapsePrice">
                                <div className='ckboxbo-icon'>
                                    <i className="ri-money-rupee-circle-line ri-xl mr-10"></i>
                                    <span>Price Details</span>
                                </div>
                                <div className='ckboxbo-itemscount'> <i className="ri-arrow-down-s-line ri-lg ml-5"></i></div>
                            </div>
                            <div className="collapse show" id="collapsePrice">
                                <div className='pricebox'>
                                    <ul>
                                        <li>
                                            <span>Items Total</span>
                                            <span>{multiCurrency(contextValues.cartSummary.mrpTotal)}</span>
                                        </li>
                                        <li>
                                            <span>Discount</span>
                                            <span className='tx-green'>-{multiCurrency(contextValues.cartSummary.discount)}</span>
                                        </li>
                                        <li>
                                            <span>Coupon Discount</span>
                                            <span className='tx-green'>-{multiCurrency(contextValues.couponSession.discount_amount)}</span>
                                        </li>
                                        <li>
                                            <span>Shipping</span>
                                            <span>{multiCurrency(contextValues.shippingAmount)}</span>
                                        </li>
                                        <li className='fw600'>
                                            <span>Total</span>
                                            <span>{multiCurrency(Number(contextValues.cartSummary.total_amount) - Number(contextValues.couponSession.discount_amount) + Number(contextValues.shippingAmount))}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <h6 className='tx-gray mb-15'>Payment Methods</h6>
                        <div className='ckboxbo mb-15' onClick={(e) => selectPaymentMethod('COD')}>
                            <div className='ckboxbo-inner' >
                                <div className='ckboxbo-icon'>
                                    <img className="mr-10" src="/images/delivery.png" style={{ width: '16%' }} />
                                    <div>
                                        <p className='tx-14 mb-0'>Cash on delivery</p>
                                        <p className='tx-12 tx-gray mb-0'>Pay with cash</p>
                                    </div>
                                </div>
                                <div className='ckboxbo-itemscount tx-14'><i className="ri-arrow-right-s-line ri-lg ml-5"></i></div>
                            </div>
                        </div>
                        <div className='ckboxbo mb-15' onClick={(e) => selectPaymentMethod('UPI')}>
                            <div className='ckboxbo-inner' >
                                <div className='ckboxbo-icon'>
                                    <img className="mr-10" src="/images/phonepe.png" style={{ width: '13%' }} />
                                    <div>
                                        <p className='tx-14 mb-0'>Pay via UPI</p>
                                        <p className='tx-12 tx-gray mb-0'>Use any registered UPI ID</p>
                                    </div>
                                </div>
                                <div className='ckboxbo-itemscount tx-14'><i className="ri-arrow-right-s-line ri-lg ml-5"></i></div>
                            </div>
                        </div>
                        <div className='ckboxbo mb-15' onClick={(e) => selectPaymentMethod('Debit/Credit Cards')}>
                            <div className='ckboxbo-inner' >
                                <div className='ckboxbo-icon'>
                                    <img className="mr-10" src="/images/creditcard.png" style={{ width: '11%' }} />
                                    <div>
                                        <p className='tx-14 mb-0'>Debit/Credit cards</p>
                                        <p className='tx-12 tx-gray mb-0'>Visa, Mastercard, RuPay & more</p>
                                    </div>
                                </div>
                                <div className='ckboxbo-itemscount tx-14'><i className="ri-arrow-right-s-line ri-lg ml-5"></i></div>
                            </div>
                        </div>
                        <div className='ckboxbo mb-15' onClick={(e) => selectPaymentMethod('Wallet')}>
                            <div className='ckboxbo-inner'>
                                <div className='ckboxbo-icon'>
                                    <img className="mr-10" src="/images/paytm.png" style={{ width: '11%' }} />
                                    <div>
                                        <p className='tx-14 mb-0'>Wallets</p>
                                        <p className='tx-12 tx-gray mb-0'>Paypal, Airtel, PayZapp & more</p>
                                    </div>
                                </div>
                                <div className='ckboxbo-itemscount tx-14'><i className="ri-arrow-right-s-line ri-lg ml-5"></i></div>
                            </div>
                        </div>
                        <div className='ckboxbo mb-15' onClick={(e) => selectPaymentMethod('Net Banking')}>
                            <div className='ckboxbo-inner' >
                                <div className='ckboxbo-icon'>
                                    <img className="mr-10" src="/images/netbanking.png" style={{ width: '13%' }} />
                                    <div>
                                        <p className='tx-14 mb-0'>Netbanking</p>
                                        <p className='tx-12 tx-gray mb-0'>Select from a list of banks</p>
                                    </div>
                                </div>
                                <div className='ckboxbo-itemscount tx-14'><i className="ri-arrow-right-s-line ri-lg ml-5"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default PaymentModal