import { useEffect, useRef, useState, useContext } from 'react';
import { ApiService } from '../Services/apiservices';
import DataContext from '../Elements/eventContext';
import Modal from 'react-bootstrap/Modal';
import { showToast } from '../Elements/toastUtlis';
import ReactStars from "react-rating-stars-component";

function ReviewModal({rowProductsData}) {
    const contextValues = useContext(DataContext);
    const [rating, setRating] = useState('');
    const [Comment, setComment] = useState('');
    const [images, setImages] = useState([]);
    const ReviewimageRef = useRef(null);
    const didMountRef = useRef(true);
    useEffect(() => {
        if (didMountRef.current) {
        }
        didMountRef.current = false;
    }, []);

    const ratingChanged = (newRating) => {
        setRating(newRating);
      };

    const removereviewimage = (image) => {
        setImages((prevImages) => prevImages.filter((img) => img !== image));
    };

    const handleUpload = () => {
        ReviewimageRef.current.click();
    };

    const handlereviewimage = (e) => {
        const files = e.target.files;
        const imageArray = [...images]; // Copy the existing images array
        for (let i = 0; i < files.length; i++) {
            imageArray.push(files[i]);
        }
        setImages(imageArray);
    };

    const handleSubmit = async () => {
        if (rating <= 0) {
            showToast('error','Please select Ratings',2000);
            return;
        }
        if (Comment === '' || Comment === null) {
            showToast('error','Please  Write Review',2000);
            return;
        }
        const formData = new FormData();
        formData.append('rating', rating);
        formData.append('review', Comment);
        formData.append('product_id', rowProductsData.product_id);

        for (let i = 0; i < images.length; i++) {
            formData.append('images[]', images[i]); // Use 'images[]' to handle multiple image files on the backend
        }

        ApiService.postData("submitreview", formData)
            .then((res) => {
                if (res.status === "success") {
                    showToast('success','Review Submitted Successfully',2000);
                    window.location.reload();
                } else {
                    showToast('error',res.message,2000);
                    return false;
                }
            })
            .catch((error) => {
                // Handle error
            });
    };

    const reviewModal = () => {
        contextValues.setToggleReviewModal(!contextValues.toggleReviewModal)
    }
    return (
        <>
            <Modal show={contextValues.toggleReviewModal} onHide={(e) => reviewModal()} className='reviewModal right'>
                <div className="reviewModal-content">
                    <button type="button" className="btn-close" onClick={(e) => reviewModal()}></button>
                    <div className="reviewModal-header">
                        <div>
                            <h4 className="reviewModal-title">Add a Review</h4>
                            <p>Your email address will not be published. Required fields are marked * </p>
                        </div> 
                    </div>
                    <div className="reviewModal-body">
                        <div className="review-form mb-3">
                            <label className="mb-0">Your Rating <span>*</span></label>
                            <ReactStars count={5} onChange={ratingChanged} size={24} activeColor="#ffd700" />
                        </div>
                        <div className="review-form mb-3">
                            <label>Your Review <span>*</span></label>
                            <textarea
                                name="comment"
                                className="required"

                                onChange={(e) => setComment(e.target.value)}
                            />
                        </div>
                        <div className="review-form mb-1">
                            <div className="vi-box-section">
                                <div className="vi-box">
                                    <div className="vi-box-inner">
                                        <img
                                            src="/images/defaultimage2.png" // Always show the default image
                                            onClick={handleUpload}
                                            alt="Add Images"
                                        />
                                        <div className="tx-12">Add Images</div>
                                    </div>
                                </div>

                                <input
                                    type="file"
                                    ref={ReviewimageRef}
                                    style={{ display: "none" }}
                                    multiple // Allow multiple file selection
                                    onChange={handlereviewimage}
                                />
                                {
                                    images.map((image, index) => (

                                        <div className="vi-box" key={index}>
                                            <div className="vi-box-inner">
                                                <div className="remove" onClick={(e) => removereviewimage(image)}><i class="ri-close-line"></i></div>
                                                <img src={URL.createObjectURL(image)} alt="Review Image" />
                                            </div>
                                        </div>

                                    ))
                                }
                            </div>

                        </div>
                        <div className="review-form mb-3">
                            <p className="tx-12 tx-color-02">  Upload images. Maximum count: 3, size: 2MB</p>
                        </div>

                        <div className="review-form mb-3">
                            <button className="btn btn-primary btn-md" onClick={handleSubmit}>Submit</button>
                        </div>
                    </div>

                </div>
            </Modal>
        </>
    );
}

export default ReviewModal;
