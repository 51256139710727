import React, { useEffect, useState, useRef, useContext } from "react";
import AccountSidebar from "./account_sidebar";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import DataContext from "../../Components/Elements/eventContext";
import { BrowserView, MobileView } from "react-device-detect";
import MobHeader from "../../Components/Header/mobheader";
import Skeleton from "react-loading-skeleton";
function HelpandSupport() {
    const contextValues = useContext(DataContext);
    const didMountRef = useRef(true);
    const [skeletonStatus, setSkeletonStatus] = useState(false);
    useEffect(() => {
        if (didMountRef.current) {
            setSkeletonStatus(true)
            setTimeout(() => {
                setSkeletonStatus(false)
            }, 500);
        }
        didMountRef.current = false;
    }, []);
    return (
        <>
            <BrowserView>
                <Header state="inner-header" />
                <div className="subheader">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Help & Support</li>
                                    </ol>
                                </nav>

                            </div>
                        </div>
                    </div>
                </div>
                <section className="section-gap-medium">
                    <div className="container">
                        <div className="row">
                            <AccountSidebar/>
                            <div className="col-lg-9">
                                <div className="acpanel">
                                    <div className="acpanel-header">
                                        <h4>Help & Support</h4>
                                    </div>
                                    <div className="acpanel-body">
                                        <div className="p-5 helpbox text-center">
                                            <img className="img-fluid mb-2" src="/images/support-img.png" style={{ width: '200px' }}></img>
                                            <h6>How can we help you today?</h6>
                                            <p className="tx-13 mb-0"> Our customer service team will be able to assist you with any order or query</p>
                                        </div>
                                        <div className="macc-list">
                                            <ul>
                                                <li>
                                                    <a href={"mailto:" + contextValues.settingData.admin_email}>
                                                        <div className="macc-list-icon">
                                                            <img src="/images/mail-inbox-app.png"></img>
                                                        </div>
                                                        <div>
                                                            <h6 className="mb-0">Email Us</h6>
                                                            <p className="mb-0"> Write to Dryfu directly for any query</p>
                                                        </div>
                                                        <i class="ri-arrow-right-s-line ri-xl"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={"tel:" + contextValues.settingData.admin_mobile}>
                                                        <div className="macc-list-icon">
                                                            <img src="/images/phone-call.png"></img>
                                                        </div>
                                                        <div>
                                                            <h6 className="mb-0">Call Us</h6>
                                                            <p className="mb-0">Get in touch and we will be happy to help you</p>
                                                        </div>
                                                        <i class="ri-arrow-right-s-line ri-xl"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={"https://wa.me/" + contextValues.settingData.admin_whatsapp_no} target="new">
                                                        <div className="macc-list-icon">
                                                            <img src="/images/whatsapp.png"></img>
                                                        </div>
                                                        <div>
                                                            <h6 className="mb-0">Whatsapp</h6>
                                                            <p className="mb-0"> Get in touch and we will be happy to help you</p>
                                                        </div>
                                                        <i class="ri-arrow-right-s-line ri-xl"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </BrowserView>
            <MobileView>
                <MobHeader PageName="Help & Support" Route="account/account-overview" />
                <div id="appCapsule">
                    {skeletonStatus ? <>
                        <div className="p-5 helpbox text-center">
                            <Skeleton height={150} width={200} className="tx-13 mb-2" />
                            <h6><Skeleton width={150} /></h6>
                            <p className="tx-13 mb-0"> <Skeleton width={300} /></p>
                            <p className="tx-13 mb-0"> <Skeleton width={200} /></p>
                        </div>
                        <div className="macc-list">
                            <ul>
                                <li>
                                    <a href="#">
                                        <div className="macc-list-icon">
                                            <Skeleton circle={true} height={35} width={35} />
                                        </div>
                                        <div>
                                            <h6 className="mb-0"><Skeleton width={150} /></h6>
                                            <p className="mb-0"><Skeleton width={250} /></p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <div className="macc-list-icon">
                                            <Skeleton circle={true} height={35} width={35} />
                                        </div>
                                        <div>
                                            <h6 className="mb-0"><Skeleton width={150} /></h6>
                                            <p className="mb-0"><Skeleton width={250} /></p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <div className="macc-list-icon">
                                            <Skeleton circle={true} height={35} width={35} />
                                        </div>
                                        <div>
                                            <h6 className="mb-0"><Skeleton width={150} /></h6>
                                            <p className="mb-0"><Skeleton width={250} /></p>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </> : <>
                        <div className="p-5 helpbox text-center">
                            <img className="img-fluid mb-2" src="/images/support-img.png" style={{ width: '200px' }}></img>
                            <h6>How can we help you today?</h6>
                            <p className="tx-13 mb-0"> Our customer service team will be able to assist you with any order or query</p>
                        </div>
                        <div className="macc-list">
                            <ul>
                                <li>
                                    <a href={"mailto:" + contextValues.settingData.admin_email}>
                                        <div className="macc-list-icon">
                                            <img src="/images/mail-inbox-app.png"></img>
                                        </div>
                                        <div>
                                            <h6 className="mb-0">Email Us</h6>
                                            <p className="mb-0"> Write to Dryfu directly for any query</p>
                                        </div>
                                        <i class="ri-arrow-right-s-line ri-xl"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href={"tel:" + contextValues.settingData.admin_mobile}>
                                        <div className="macc-list-icon">
                                            <img src="/images/phone-call.png"></img>
                                        </div>
                                        <div>
                                            <h6 className="mb-0">Call Us</h6>
                                            <p className="mb-0">Get in touch and we will be happy to help you</p>
                                        </div>
                                        <i class="ri-arrow-right-s-line ri-xl"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href={"https://wa.me/" + contextValues.settingData.admin_whatsapp_no} target="new">
                                        <div className="macc-list-icon">
                                            <img src="/images/whatsapp.png"></img>
                                        </div>
                                        <div>
                                            <h6 className="mb-0">Whatsapp</h6>
                                            <p className="mb-0"> Get in touch and we will be happy to help you</p>
                                        </div>
                                        <i class="ri-arrow-right-s-line ri-xl"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </>}

                </div>
            </MobileView>



        </>
    );
}
export default HelpandSupport;
