import React from 'react'
import { BrowserView, MobileView } from "react-device-detect";
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import MobileHeader from '../../Components/Elements/mobile_header';
function Error404() {
  return (
    <>
    
  
    <BrowserView>
    <Header />
    <section className="sec-pad text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-12" >
          <h2>404. PAGE NOT FOUND</h2>
         <p>The page you were looking for could not be found. It might have been removed, renamed, or did not exist in the first place.</p>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </BrowserView>

<MobileView>
<MobileHeader/>
<section className="sec-pad text-center">
   <div className="container">
     <div className="row">
     <div className="col-lg-12 col-12" >
         <h2>404. PAGE NOT FOUND</h2>
         <p>The page you were looking for could not be found. It might have been removed, renamed, or did not exist in the first place.</p>
       </div>
     </div>
   </div>
 </section>

</MobileView>
</>
  )
}

export default Error404