import React, { useEffect, useState, useContext } from "react";
import constant from "../../Components/Services/constant";
import { ApiService } from "../../Components/Services/apiservices";
import StarRating from "./starrating";
import multiCurrency from "../../Components/Elements/multi_currrency";
import { showToast } from "./toastUtlis";
import { addToCart, minusToCart } from "./add_to_cart";
import DataContext from "./eventContext";
import Loader from "react-js-loader";
import VariationModal from "../Modals/variation.modal";
function ProductBox({ productValue, indexProduct }) {
    const contextValues = useContext(DataContext);
    const [selvararray, setSelvararray] = useState([]);
    const [setSession, SetSession] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [existInCart, setExistInCart] = useState(false);
    useEffect(() => {
        //if (didMountRef.current) {
        SetSession(localStorage.getItem("USER_TOKEN"));
        setSelvararray(productValue && productValue.default_variation_first ? productValue.default_variation_first.pv_variation.split(',') : [])
        const quantityIndex = contextValues.cartSessionData.findIndex((item) => {
            return (
                item.product_id === productValue.product_id &&
                JSON.stringify(item.product_variation) === JSON.stringify(productValue && productValue.default_variation_first ? productValue.default_variation_first.pv_variation.split(',') : [])
            );
        });
        const quantity = quantityIndex !== -1 ? contextValues.cartSessionData[quantityIndex].quantity : 0;
        const exist = quantityIndex !== -1 ? true : false;
        setExistInCart(exist)
        setQuantity(quantity)
        //}
        // didMountRef.current = false;
    }, [productValue, indexProduct, contextValues]);

    const addtofav = (productId) => {
        const dataString = {
            product_id: productId,
        };
        ApiService.postData("add-to-fav", dataString).then((res) => {
            if (res.data.status === "success") {
                if (res.data.notification === "ri-heart-fill") {
                    showToast('success', 'Added to Wishlist', 1000);
                } else {
                    showToast('success', 'Removed from Wishlist', 1000);
                }
                var element = document.getElementsByClassName("wishlisticon" + productId)[0];
                if (element) {
                    element.classList.remove("ri-heart-line", "ri-heart-fill");
                    element.classList.add(res.data.notification);
                }
            }
        });
    };
    let mrpValue = parseFloat(productValue.product_price);
    let sellingPriceValue = parseFloat(
        productValue.product_selling_price
    );
    let discount = 0;
    if (!isNaN(mrpValue) && !isNaN(sellingPriceValue)) {
        discount = Math.round(((mrpValue - sellingPriceValue) / mrpValue) * 100);
    }

    const handleAddToCart = async (productValue) => {
        contextValues.setSpinnerCubLoader(productValue.product_id)
        const productData = {
            product_id: Number(productValue.product_id),
            product_name: productValue.product_name,
            product_slug: productValue.product_slug,
            product_image: productValue.product_image
                ? productValue.product_image
                : constant.DEFAULT_IMAGE,
            product_type: Number(productValue.product_type),
            product_price: Number(productValue.product_price),
            product_selling_price: Number(productValue.product_selling_price),
            product_discount: productValue.product_discount,
            product_variation: selvararray,
            product_category_id: productValue.product_category_id,
        };
        const updateStatus = await addToCart(productData, quantity, contextValues);
        if (updateStatus) {
            setQuantity(quantity + 1);
            contextValues.setSpinnerCubLoader(0)
        } else {
            contextValues.setSpinnerCubLoader(0)
        }
    };
    const plusToCart = async (productValue) => {
        contextValues.setSpinnerCubLoader(productValue.product_id)
        const productData = {
            product_id: Number(productValue.product_id),
            product_name: productValue.product_name,
            product_slug: productValue.product_slug,
            product_image: productValue.product_image
                ? productValue.product_image
                : constant.DEFAULT_IMAGE,
            product_type: Number(productValue.product_type),
            product_price: Number(productValue.product_price),
            product_selling_price: Number(productValue.product_selling_price),
            product_discount: productValue.product_discount,
            product_variation: selvararray,
            product_category_id: productValue.product_category_id,
        };
        const updateStatus = await addToCart(productData, quantity+Number(1), contextValues);
        if (updateStatus) {
            setQuantity(quantity + 1);
            contextValues.setSpinnerCubLoader(0)
        } else {
            contextValues.setSpinnerCubLoader(0)
        }
    };
    const removeToCart = (productValue) => {
        contextValues.setSpinnerCubLoader(productValue.product_id)
        if (minusToCart(productValue, selvararray, contextValues)) {
            setQuantity(quantity - 1);
            contextValues.setSpinnerCubLoader(0)
        } else {
            contextValues.setSpinnerCubLoader(0)
        }
    };
    const variationModal = (productData) => {
        contextValues.setProductData(productData)
        contextValues.setToggleVariationModal(!contextValues.toggleVariationModal)
    }
    const quickViewModal = (productData) => {
        console.log("productData",productData);
        contextValues.setProductData(productData)
        contextValues.setToggleQuickViewModal(!contextValues.toggleQuickViewModal)
    }
    return (
        <>
            <div className="cproduct" key={indexProduct}>
                <a href={"/product/" + productValue.product_slug} className="cproduct-media">
                    <div className="offtags">
                        <svg width="35" height="34" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M28.9499 0C28.3999 0 27.9361 1.44696 27.9361 2.60412V27.9718L24.5708 25.9718L21.2055 27.9718L17.8402 25.9718L14.4749 27.9718L11.1096 25.9718L7.74436 27.9718L4.37907 25.9718L1.01378 27.9718V2.6037C1.01378 1.44655 0.549931 0 0 0H28.9499Z" fill="#538CEE"></path></svg>
                        <span>{discount}% Off</span>
                    </div>
                    <figure className="cproduct-media-img">
                        <img src={productValue.product_image != null ? productValue.product_image : constant.DEFAULT_IMAGE} alt={productValue.product_name} title={productValue.product_name} width='280' height='315'></img>
                    </figure>
                    {productValue.product_label_name && productValue.product_label_name !== "" ? (
                        <div className="cproduct-label-group">
                            {productValue.product_label_name.split(", ").map((tagvalue, indextag) => {
                                return (
                                    <label className="cproduct-label label-sale" key={indextag}>{tagvalue}</label>
                                );
                            })}
                        </div>
                    ) : null}
                    {/* <div className="cproduct-action-vertical">
                        {setSession ? (
                            productValue.ufp_id && productValue.ufp_id > 0 ? (
                                <a href="javascript:void(0)" className="cpav-button" onClick={(e) => addtofav(productValue.product_id)}><i className={"ri-heart-3-fill wishlisticon" + productValue.product_id}></i></a>
                            ) : (
                                <a href="javascript:void(0)" className="cpav-button" onClick={(e) => addtofav(productValue.product_id)}><i className={"ri-heart-3-line wishlisticon" + productValue.product_id}></i></a>
                            )
                        ) : (
                            <a href="javascript:void(0)" className="cpav-button" data-bs-toggle="modal" data-bs-target="#loginModal"><i className={"ri-heart-3-line wishlisticon" + productValue.product_id}></i></a>
                        )}
                    </div> */}
                    <a href="javascript:;" className="quickviewbutton" onClick={(e) => quickViewModal(productValue)}>Quick View</a>
                </a>
                <div className="cproduct-details">
                    <h2 className="cproduct-name"><a href={"/product/" + productValue.product_slug}>{productValue.product_name}</a></h2>
                    {/* {Number(productValue.product_type) == 1 && <h2 className="cproduct-name">{productValue.default_variation_first && productValue.default_variation_first.pv_variation ? productValue.default_variation_first.pv_variation : ''}</h2>} */}
                    {productValue.has_many_reviews && productValue.has_many_reviews.length > 0 && productValue.product_rating &&
                        productValue.product_rating > 0 ? (
                        <div className="cratings-container"><StarRating numberOfStars={productValue.product_rating} reviewCount={productValue.has_many_reviews.length} /></div>
                    ) : (
                        ""
                    )}
                    <div className="cproduct-footer">
                        <div className="cproduct-price">
                            <ins className="new-price">{multiCurrency(productValue.product_selling_price)}</ins>
                            {discount > 0 ? (
                                <>
                                    <del className="old-price">{multiCurrency(productValue.product_price)}</del>
                                </>
                            ) : null}
                        </div>
                        {existInCart ? (
                            <div className="qtyadd qtyadd-increse">
                                {contextValues.spinnerCubLoader == productValue.product_id ? <Loader type="spinner-cub" bgColor={'#ffffff'} color={'#ffffff'} size={20} /> : <>
                                    <span onClick={(e) => removeToCart(productValue)}><i className="ri-subtract-line"></i></span>
                                    <span className="number">{quantity}</span>
                                    <span onClick={(e) => plusToCart(productValue)}><i className="ri-add-line"></i></span>
                                </>
                                }
                            </div>
                        ) : (
                            <>
                            {Number(productValue.product_type) == 1 ? <div className="qtyadd" onClick={(e) => variationModal(productValue)}>
                                <span>{contextValues.spinnerCubLoader == productValue.product_id ? <Loader type="spinner-cub" bgColor={'#000000'} color={'#000000'} size={20} /> : 'ADD'}</span>
                            </div> : <div className="qtyadd" onClick={(e) => handleAddToCart(productValue)}>
                                <span>{contextValues.spinnerCubLoader == productValue.product_id ? <Loader type="spinner-cub" bgColor={'#000000'} color={'#000000'} size={20} /> : 'ADD'}</span>
                            </div>}</>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
export default ProductBox;
