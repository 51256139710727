import React, { useEffect, useState, useRef, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DataContext from '../Elements/eventContext';
import sessionCartData from "../Elements/cart_session_data";
import { ApiService } from "../Services/apiservices";
function MobHeader({ PageName, Route, cartCount = 0 }) {
    const didMountRef = useRef(true);
    const navigate = useNavigate();
    const [showCart, setShowCart] = useState(false);
    const handleShowCart = () => setShowCart(true);
    const handleChildCartData = (status) => {
        setShowCart(status)
    };
    const handleClose = () => {
        setShowCart(false);
    };
    const contextValues = useContext(DataContext);
    const cartModal = () => {
        contextValues.setToggleCartModal(!contextValues.toggleCartModal)
    }
    const [showHeaderStatus, SetShowHeaderStatus] = useState(false)
    const location = useLocation();
    const { slug } = useParams();
    const dataArray = sessionCartData();
    useEffect(() => {
        if (didMountRef.current) {
            getuserData()
            getsettingData()
            const currentPath = location.pathname;
            const isCategoryPage = currentPath.includes("/category");
            const isProductDetailsPage = currentPath.includes("/product/" + slug);
            const isTagPage = currentPath.includes("/collection/tag/" + slug);
            if (isCategoryPage || isProductDetailsPage || isTagPage) {
                SetShowHeaderStatus(true)
            } else {
                SetShowHeaderStatus(false)
            }
        }
        didMountRef.current = false;
        contextValues.setAddressSession(dataArray[0])
        contextValues.setCartSessionData(dataArray[1])
        contextValues.setCouponSession(dataArray[2])
        contextValues.setCartSummary(dataArray[3])
        contextValues.setCartCount(dataArray[1].length)
        contextValues.setUserToken(dataArray[5])
        const storedLocation = JSON.parse(localStorage.getItem("DELIVERY_LOCATION"));
        contextValues.setDeliveryLocationData(storedLocation)
    }, []);
    const getuserData = () => {
        ApiService.fetchData("get-user-data").then((res) => {
            if (res.status == "success") {
                contextValues.setRowUserData(res.rowUserData);
            } else {
                localStorage.removeItem("USER_TOKEN");
                navigate("/");
            }
        });
    };
    const getsettingData = () => {
        ApiService.fetchData("settingsdata").then((res) => {
          if (res.status === "success") {
            contextValues.setSettingData(res.sitesettings)
            contextValues.setSettingImageBaseUrl(res.setting_image_path);
          }
        });
      };
    return (
        <>
            <header className="mobile-header">
                <div className="mobile-header-left">
                    <a href={"/" + Route} className="mobile-header-title">
                        <img src="/images/back.png" className="wd-24 mr-15"></img>
                        {PageName}
                    </a>
                </div>
            </header>
        </>
    );
}
export default MobHeader;
