import React, { useEffect, useRef, useState, useCallback } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { ApiService } from "../../Components/Services/apiservices";
import { Swiper, SwiperSlide } from "swiper/react";
import Skeleton from "react-loading-skeleton";
import ProductBox from "./product_box";
function CategoryWiseProducts({ indexLoop, onParentData, dataObj }) {
  const didMountRef = useRef(true);
  const [homeCategoriesData, setHomeCategoriesData] = useState([]);
  const [loading, setLoading] = useState();
  useEffect(() => {
    if (didMountRef.current) {
      getHomeCategoryData();
    }
    didMountRef.current = false;
  }, [homeCategoriesData]);

  const getHomeCategoryData = () => {
    setLoading(true);
    const dataString = {
      data: dataObj,
    }
    ApiService.postData("category-wise-products-new", dataString).then((res) => {
      if (res.status == "success") {
        setHomeCategoriesData(res.categoriesData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const tagsRef = useRef(null);
  const taghandlePrev = useCallback(() => {
    if (!tagsRef.current) return;
    tagsRef.current.swiper.slidePrev();
  }, []);

  const taghandleNext = useCallback(() => {
    if (!tagsRef.current) return;
    tagsRef.current.swiper.slideNext();
  }, [])
  return (
    <>
      {loading == true ? (
        <>
          <BrowserView>
            <div className="row g-2">
              {[...Array(4)].map((_, index) => (
                <div key={index} className="col-lg-3 col-6">
                  <div className="product">
                    <figure className="product-media">
                      <Skeleton variant="text" width={300} height={250} />
                    </figure>
                    <div className="product-details">
                      <h3 className="product-name">
                        <Skeleton variant="text" width={150} />
                      </h3>
                      <div className="product-price">
                        <Skeleton variant="text" width={150} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </BrowserView>
          <MobileView>
            <div className="row g-2">
              {[...Array(4)].map((_, index) => (
                <div key={index} className="col-lg-3 col-6">
                  <div className="product">
                    <figure className="product-media">
                      <Skeleton variant="text" width={300} height={160} />
                    </figure>
                    <div className="product-details">
                      <h3 className="product-name">
                        <Skeleton variant="text" width={150} />
                      </h3>
                      <div className="product-price">
                        <Skeleton variant="text" width={150} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </MobileView>
        </>
      ) : (
        <>
          {homeCategoriesData.map((value, index) => {
            if (value.catProducts.length > 0) {
              if (dataObj.home_display_type === 2) {
                return (
                  <>
                    <div className="page-title mb-4 mt-4" key={index}>
                      <h2 className="mb-0">{dataObj.home_product_name}</h2>
                      <a href={"/collection/category/" + value.cat_slug} className="viewmorebutton">View More <i className="ri-arrow-right-s-line ms-2"></i></a>
                    </div>
                    <div className={"row g-3 " + dataObj.home_col_grid + dataObj.home_column}>
                      {value.catProducts.map((subvalue, indexProduct) => {
                        return (
                          <ProductBox productValue={subvalue} indexProduct={indexProduct} />
                        );
                      })}
                    </div>
                  </>
                );
              } else {
                return (
                  <>
                    <div className="page-title mb-4 mt-4" key={index}>
                      <h2 className="mb-0">{dataObj.home_product_name}</h2>
                      <a href={"/collection/category/" + value.cat_slug} className="viewmorebutton">View More <i className="ri-arrow-right-s-line ms-2"></i></a>
                    </div>
                    <div className="productSlider">
                      <Swiper
                        loop={true}
                        spaceBetween={15}
                        navigation={{
                          nextEl: ".swiper-button-next",
                          prevEl: ".swiper-button-prev",
                        }}
                        breakpoints={{
                          0: {
                            slidesPerView: dataObj.home_column,
                          },
                          600: {
                            slidesPerView: dataObj.home_column,
                          },
                          1000: {
                            slidesPerView: dataObj.home_column,
                          },
                        }}
                        ref={tagsRef}
                      >
                        {value.catProducts.map((subvalue, indexProduct) => {
                          return (
                            <SwiperSlide key={indexProduct}>
                              <ProductBox productValue={subvalue} indexProduct={indexProduct} />
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                      <div className='swip-buttons'>
                        <div className="swip-button swip-button-prev" style={{ alignItems: 'center' }} onClick={taghandlePrev} >
                          <span>
                            <i class="ri-arrow-left-line"></i>
                          </span>
                        </div>
                        <div className="swiper-button swip-button-next" onClick={taghandleNext} style={{ alignItems: 'center' }}>
                          <span>
                            <i class="ri-arrow-right-line"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                );
              }
            }
          })}
        </>
      )}
    </>
  );
}
export default CategoryWiseProducts;
