import React, { useEffect, useRef, useState, useContext } from 'react'
import { ApiService } from '../../Components/Services/apiservices'
import DataContext from '../Elements/eventContext';
import { addToCart, minusToCart } from '../Elements/add_to_cart';
import Loader from "react-js-loader";
import multiCurrency from '../Elements/multi_currrency';
import constant from '../Services/constant';
import Modal from 'react-bootstrap/Modal';
function VariationModal() {
    const didMountRef = useRef(true);
    const contextValues = useContext(DataContext);
    const [rowProductData, setRowProductData] = useState({});
    const [quantity, setQuantity] = useState(0);
    const [selvararray, setSelvararray] = useState([]);
    const [proccessLoader, setProccessLoader] = useState(false);
    useEffect(() => {
        if (didMountRef.current) {
            getVariationData()
        }
        didMountRef.current = false;
    }, [contextValues]);

    const getVariationData = () => {
        const productData = {
            product_id: contextValues.productData.product_id
        };
        contextValues.setSpinnerCubLoader(1)
        ApiService.postData("getVariationData", productData).then((res) => {
            if (res.status === 'success') {
                setRowProductData(res.rowProductData)
                setSelvararray(res.rowProductData && res.rowProductData.default_variation_first ? res.rowProductData.default_variation_first.pv_variation.split(',') : [])
                contextValues.setSpinnerCubLoader(0)
            } else {
                contextValues.setSpinnerCubLoader(0)
            }
        }).catch((error) => {
            contextValues.setSpinnerCubLoader(0)
        });
    }

    const variationModal = () => {
        contextValues.setToggleVariationModal(!contextValues.toggleVariationModal)
    }

    const handleAddToCart = async (productValue, value) => {
        setProccessLoader(value.pv_id)
        const varArray = value && value.pv_variation ? value.pv_variation.split(',') : []
        const productData = {
            product_id: Number(productValue.product_id),
            product_name: productValue.product_name,
            product_slug: productValue.product_slug,
            product_image: productValue.product_image
                ? productValue.product_image
                : constant.DEFAULT_IMAGE,
            product_type: Number(productValue.product_type),
            product_price: Number(value.pv_price),
            product_selling_price: Number(value.pv_sellingprice),
            product_discount: value.pv_discount,
            product_variation: varArray,
            product_category_id: productValue.product_category_id,
        };
        const updateStatus = await addToCart(productData, quantity, contextValues);
        if (updateStatus) {
            setQuantity(quantity + 1);
            setProccessLoader(0)
        } else {
            setProccessLoader(0)
        }
    };
    return (
        <>
            <Modal show={contextValues.toggleVariationModal} onHide={(e) => variationModal()}>
                <div className='cartSection'>
                    <div className='cartHeader'>
                        <h6 className='mb-0'>{ contextValues.spinnerCubLoader > 0 ? rowProductData.product_name : ''}</h6>
                        <button type="button" className="cart-close" onClick={(e) => variationModal()}><i className="ri-close-line ri-xl"></i></button>
                    </div>
                    <div className='cartScoller'>
                        {
                            contextValues.spinnerCubLoader > 0 ? (
                                <Loader type="spinner-cub" bgColor="#1b8057" color="#1b8057" size={50} />
                            ) : (
                                <>
                                    {rowProductData && rowProductData.product_variations && rowProductData.product_variations.length > 0 && rowProductData.product_variations.map((value, index) => {
                                        return (
                                            <div className='cartProduct' key={index}>
                                                <figure className='cartProduct-media'>
                                                    <a href={`/product/${rowProductData.product_slug}`}>
                                                        <img src={value.pv_image ? value.pv_image : rowProductData.product_image} alt={rowProductData.product_name} />
                                                    </a>
                                                </figure>
                                                <div className='cartProduct-details'>
                                                    <a className='cartProductName' href={`/product/${rowProductData.product_slug}`}>
                                                        {value.pv_variation}
                                                    </a>
                                                    <div className='cartProduct-footer'>
                                                        <div className='cartProductPrice'>
                                                            <ins className="new-price">{multiCurrency(value.pv_sellingprice)}</ins>
                                                            <del className="old-price">{multiCurrency(value.pv_price)}</del>
                                                        </div>
                                                        <div className='cartProductqty'>
                                                            <div className="qtyadd" onClick={(e) => handleAddToCart(rowProductData, value)}>
                                                                <span>{proccessLoader == value.pv_id ? <Loader type="spinner-cub" bgColor={'#000000'} color={'#000000'} size={20} /> : 'ADD'}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>
                            )
                        }

                    </div>
                </div>
            </Modal>
        </>

    )
}

export default VariationModal