import React, { useEffect, useRef, useState } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { ApiService } from '../../Components/Services/apiservices'
import { useNavigate,useParams } from "react-router-dom";

function Pages() {
  const navigate = useNavigate();
  const didMountRef = useRef(true);
  const { slug } = useParams();
  const [pageContent, setPageContent] = useState("");
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    if (didMountRef.current) {
      const PageData = {
        slug: slug,
      };
  
      ApiService.postData("pagecontent", PageData).then((res) => {
        if (res.status === "success") {
          setPageData(res.data)

          setPageContent(res.data.page_content)
        }else {
          navigate('/error_404')
        }
      });
    }
    didMountRef.current = false;
  }, [slug]);
  return (
    <>
      <Header />
      <div className="subheader">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                            <h1>{pageData.page_name}</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">{pageData.page_name}</li>
                                    </ol>
                                </nav>

                            </div>
                        </div>
                    </div>
                </div>

      {pageContent != null ? (
          <div dangerouslySetInnerHTML={{ __html: pageContent }}></div>
        ) : (
          ""
        )}
      <Footer />

    </>

  )
}

export default Pages